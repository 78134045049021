import moment from "moment";
import { iUser } from "../interfaces/interfaces";

export function isNumber(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value)
}


export function ConverterNumber(value: any) {
    return isNumber(value) ? value : 0;
}



export function ExtractTimeOfDateTime(date: string) {
    return moment(date).format("HH:mm:ss")
}

export function getDateTimeStart() {
    let hoje = moment();
    return moment(hoje).startOf('day').format("yyyy-MM-DD HH:mm:ss")
}

export function getLast30Days() {
    return moment().subtract(30, 'days').startOf('day').format("yyyy-MM-DD HH:mm:ss")
}


export function getDateTimeEnd() {
    let hoje = moment();
    return moment(hoje).endOf('day').format("yyyy-MM-DD HH:mm:ss")
}

export function getDateToday() {
    let hoje = moment();
    return moment(hoje).endOf('day').format("yyyy-MM-DD")
}

export function getDateToBd(date: string | Date) {
    return moment(date).endOf('day').format("yyyy-MM-DD");
}
export function getDateView(date: string) {
    return moment(date).endOf('day').format("DD MMM YY");
}
export function getDateViewAgo(date: string) {
    return moment(date).endOf('day').fromNow();
}


export function isDateExpired(date: string) {
    return moment(date).isBefore(moment());
}


export function converteObjLogin(userAPI: any) {
    let user: iUser = {
        iduser: userAPI.iduser,
        empresa_avatar: userAPI.empresaAvatar,
        fantasia: userAPI.fantasia,
        host: '', // Valor padrão, já que não existe no segundo objeto
        email: userAPI.email,
        idcliente: userAPI.idclient,
        idempresa: userAPI.idempresa,
        idgroup: userAPI.idgroup,
        login: userAPI.login,
        name: userAPI.name,
        nivel: userAPI.nivel,
        phone: userAPI.phone,
        photo: userAPI.photo,
        isAuthenticated: false, // Valor padrão, já que não existe no segundo objeto
        token: userAPI.token ?? '' // Usa um valor vazio se token for undefined
    };
    return user
}