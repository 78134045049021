import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { itemVehicle, itemSettingsAlerts, itemAlerts, itemCercas } from '../../interfaces/types';
import { selectUser } from '../../redux/userSlice';
import httpCommon from '../../services/http-common';
import { GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';
import { ctrlButtons } from '../../interfaces/interfaces';

interface DialogProps {
    open: boolean
    onClose: (modo: boolean) => void
    onCloseToUpdate: () => void
    alert: itemSettingsAlerts
}

const ImgBox = styled.img`
    margin: 0 auto;
    height: 26px;
`

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    SaveShow: true
}



const ShowCercasNoAlertModal: React.FC<DialogProps> = (props) => {


    const { user } = useSelector(selectUser);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<itemCercas[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);

    const GetDados = async () => {
        try {
            setIsLoading(true)
            let payload = {
                alerta_id: props.alert.alerta_id,
                idcliente: user.idcliente,
                nivel: user.nivel
            }
            const result = await httpCommon.post("ListaCercasNoRegra", payload)
            let resp: itemCercas[] = result.data.dados;
            resp = resp.filter(i => {
                if (i.id > 0) {
                    return i
                }
            })
            setListDatagrid(resp)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const SaveSel = async () => {
        try {
            if (!selectdRows.length) {
                return
            }
            setIsLoading(true)
            let payload = {
                alerta_id: props.alert.alerta_id,
                itens: selectdRows
            }
            console.log(payload)
            const result = await httpCommon.post("AddCercasInRule", payload)
            setIsLoading(false)
            props.onCloseToUpdate()
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        if (props.open) {
            GetDados()
        }
    }, [props.open])

    const columns: GridColDef<itemCercas>[] = [
        {
            field: 'id',
            headerName: 'Cód',
            width: 80
        },
        {
            field: 'icon', headerName: '', width: 80,
            align: 'center',
            renderCell: (params) => {
                let img: string = ''
                try {
                    img = require(`../../assets/icons_flags/${params.row.icone}`)
                } catch (error) {
                    console.log(params.formattedValue, params.row.icone)
                }
                return (
                    <>
                        {<ImgBox src={img} alt={params.row.icone} />}
                    </>
                );
            }


        },
        {
            field: 'nome',
            headerName: 'Nome',
            width: 200
        },
        {
            field: 'ender',
            headerName: 'Endereço',
            width: 400
        },
    ];


    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
    }


    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, minHeight: 500 } }}
                maxWidth="md"
                open={props.open}
                onClose={props.onClose}
            >
                <DialogTitle>Cercas Sem Regras</DialogTitle>
                <DialogContent>
                    <WidgetDataGridComp
                        isLoading={isLoading}
                        rows={listDatagrid}
                        columns={columns}
                        CheckBoxSelect={true}
                        SelectionOnClickRow={true}
                        initialBtns={initialBtns}
                        handleSelRow={(newSelectionModel) => {
                            setSel(newSelectionModel)
                        }}
                        onRefresClick={() => GetDados()}
                        onSaveClick={() => SaveSel()}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)} >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
export default ShowCercasNoAlertModal