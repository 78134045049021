import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { itemVehicle, itemSettingsAlerts, itemAlerts } from '../../interfaces/types';
import { selectUser } from '../../redux/userSlice';
import httpCommon from '../../services/http-common';
import { GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';
import { ctrlButtons } from '../../interfaces/interfaces';

interface DialogProps {
    open: boolean
    onClose: (modo: boolean) => void
    onCloseToUpdate: () => void
    alert: itemSettingsAlerts
}

const ImgBox = styled.img`
    margin: 0 auto;
    height: 26px;
`

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    SaveShow: true
}



const ShowVehicleNoAlertModal: React.FC<DialogProps> = (props) => {


    const { user } = useSelector(selectUser);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<itemVehicle[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);

    const GetDados = async () => {
        try {
            setIsLoading(true)
            let payload = {
                alerta_id: props.alert.alerta_id,
                iduser: user.idcliente,
                nivel: user.nivel
            }
            const result = await httpCommon.post("ListarCarNoRegra", payload)
            let resp: itemVehicle[] = result.data.dados;
            resp = resp.filter(i => {
                if (i.idveiculo > 0) {
                    i.id = i.idveiculo
                    return i
                }
            })
            setListDatagrid(resp)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const SaveSel = async () => {
        try {
            if (!selectdRows.length) {
                return
            }
            setIsLoading(true)
            let payload = {
                alerta_id: props.alert.alerta_id,
                itens: selectdRows
            }
            console.log(payload)
            const result = await httpCommon.post("AddVehilesInRule", payload)
            setIsLoading(false)
            props.onCloseToUpdate()
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        if (props.open) {
            GetDados()
        }
    }, [props.open])

    const columns: GridColDef<itemVehicle>[] = [
        {
            field: 'idveiculo',
            headerName: 'Cód',
            width: 80
        },
        {
            field: 'icon', headerName: '', width: 80,
            align: 'center',
            renderCell: (params) => {
                let img: string = ''
                try {
                    img = require(`../../assets/icons_maps/${params.row.avatar}.png`)
                } catch (error) {
                    console.log(params.formattedValue, params.row.avatar)
                }
                return (
                    <>
                        {<ImgBox src={img} alt={params.row.placa} />}
                    </>
                );
            }


        },
        {
            field: 'placa',
            headerName: 'Placa',
            width: 120
        },
        {
            field: 'marca',
            headerName: 'Marca',
            width: 200
        },
        {
            field: 'modelo',
            headerName: 'Modelo',
            width: 300
        },
    ];


    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
    }


    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, minHeight: 500 } }}
                maxWidth="md"
                open={props.open}
                onClose={props.onClose}
            >
                <DialogTitle>Veículos Sem Regras</DialogTitle>
                <DialogContent>
                    <WidgetDataGridComp
                        isLoading={isLoading}
                        rows={listDatagrid}
                        columns={columns}
                        CheckBoxSelect={true}
                        SelectionOnClickRow={true}
                        initialBtns={initialBtns}
                        handleSelRow={(newSelectionModel) => {
                            setSel(newSelectionModel)
                        }}
                        onRefresClick={() => GetDados()}
                        onSaveClick={() => SaveSel()}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)} >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
export default ShowVehicleNoAlertModal