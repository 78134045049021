import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { itemSettingsAlerts } from '../../interfaces/types';
import httpCommon from '../../services/http-common';
import { selectUser } from '../../redux/userSlice';
import { useSelector } from 'react-redux';
import { ctrlButtons } from '../../interfaces/interfaces';
import DetailsAlerts from '../Widgets/DetailsAlerts';
import CardOpcoesAlert from '../Widgets/CardOpcoesAlert';
import AlertForms, { ChildRef } from '../forms/AlertForms';
import { Delete, Edit, FileOpen, FolderOpen } from '@mui/icons-material';

const Container = styled('div')({
    background: '#f6f9ff',
    width: '100%',
    height: 500
});

const Subtitle = styled('div')({
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'uppercase',
});


interface DialogProps {
    open: boolean
    onClose: (modo: boolean) => void
}

const ImgBox = styled.img`
    margin: 0 auto;
    height: 26px;
`

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    addShow: true
}


const DialogSettingsAlerts: React.FC<DialogProps> = (props) => {

    const formRef = React.useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<itemSettingsAlerts[]>([]);
    const [alertSelected, setAlertSelected] = React.useState<itemSettingsAlerts>();
    const [isShowDetails, setShowDetails] = React.useState<boolean>(false);
    const [isShowDFormAlerts, setShowFormAlert] = React.useState<boolean>(false);
    const childRef = React.useRef<ChildRef>(null);

    const getIconAlert = (alert: String) => {
        switch (alert) {
            case "IGN":
                return "ignOn64.png";
            case "CERCA":
                return "flag_fim32.png";
            case "BLK":
                return "block64.png";
            case "BATCUT":
                return "cutbat.png";
            case "MOVE":
                return "anchor.png";
            case "PANIC":
                return "siren.png";
            case "VEL":
                return "90km.png";
            case "GUINCHO":
                return "guincho1.png";
            case "RAIN":
                return "rainOn.png";
            default:
                return "siren.png";
        }
    }
    /*const getPriorityAlert = (level: number) => {
        switch (level) {
            case 1:
                    element.priorityText = "Baixa";
                    element.priorityClass = 'colorYellow'
                    break;
                case 2:
                    element.priorityText = "Média";
                    element.priorityClass = 'colorOrange'
                    break;
                case 3:
                    element.priorityText = "Alta";
                    element.priorityClass = 'colorRed'
                    break;
            default:
                return "siren.png";
        }
    }*/


    const GetDados = async () => {
        try {
            setIsLoading(true)
            let payload = {
                idobj: user.idcliente,
                iduser: user.iduser,
                nivel: user.nivel
            }
            const result = await httpCommon.post("GetRegras", payload)
            let resp: itemSettingsAlerts[] = result.data.dados;
            resp = resp.map(i => {
                i.id = i.alerta_id
                return i
            })
            setListDatagrid(resp)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }


    const columns: GridColDef<itemSettingsAlerts>[] = [
        {
            field: 'id:alerta_id',
            headerName: 'Cód',
            width: 80
        },
        {
            field: 'icon', headerName: '', width: 80,
            align: 'center',
            renderCell: (params) => {
                let img: string = ''
                try {
                    img = require(`../../assets/icons_alerts/${getIconAlert(params.row.tipo)}`)
                } catch (error) {
                    console.log(params.formattedValue, params.row.tipo)
                }
                return (
                    <>
                        {<ImgBox src={img} alt={params.row.descr} />}
                    </>
                );
            }


        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 480
        },
        {
            field: 'actions', headerName: 'Eventos', width: 140,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="primary" aria-label="show Maps" onClick={e => handleRowClick(params.row)}>
                            <FolderOpen />
                        </IconButton>
                        <IconButton color="primary" aria-label="show Maps" onClick={e => EditAlert(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        }
    ];

    React.useEffect(() => {
        if (props.open) {
            GetDados()
        }
    }, [props.open])

    const handleRowClick = (item: itemSettingsAlerts) => {
        setAlertSelected(item)
        setShowDetails(true)
    };
    const handleAddClick = () => {
        setShowFormAlert(true)
    };
    const submitFormAlert = () => {
        childRef.current?.callSubmit()
    };
    const handleCloseForm = () => {
        setShowFormAlert(false)
        GetDados()
    };
    const handleCancelForm = () => {
        setShowFormAlert(false)
        setAlertSelected(undefined)
    };
    const EditAlert = (item: itemSettingsAlerts) => {
        setAlertSelected(item)
        setShowFormAlert(true)
    };
    const DeleteAlert = (item: itemSettingsAlerts) => {
        setShowFormAlert(true)
    };

    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, minHeight: 500 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Cadastro de Alertas</DialogTitle>
            <DialogContent>
                {!isShowDFormAlerts && <Container>
                    {isShowDetails ? <DetailsAlerts alert={alertSelected} /> :
                        <WidgetDataGridComp
                            isLoading={isLoading}
                            rows={listDatagrid}
                            columns={columns}
                            CheckBoxSelect={false}
                            initialBtns={initialBtns}
                            onCancelClick={() => props.onClose}
                            //onRowClick={handleRowClick}
                            onAddClick={handleAddClick}
                            onRefresClick={() => GetDados()}
                        />
                    }
                </Container>}
                {isShowDFormAlerts && <Container>
                    <AlertForms ref={childRef} closeForm={handleCloseForm} initialData={alertSelected} />
                </Container>}
            </DialogContent>
            <DialogActions>
                {isShowDFormAlerts &&
                    <>
                        <Button onClick={handleCancelForm} >
                            Cancelar
                        </Button>
                        <Button onClick={() => submitFormAlert()} >
                            Salvar
                        </Button>
                    </>
                }
                {!isShowDFormAlerts && isShowDetails && <Button onClick={() => setShowDetails(false)} >
                    Voltar
                </Button>}
                {!isShowDFormAlerts && !isShowDetails && <Button onClick={() => props.onClose(false)} >
                    Fechar
                </Button>}
            </DialogActions>
        </Dialog></>

}

export default DialogSettingsAlerts