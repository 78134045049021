

import React from 'react';
import styled from 'styled-components';

import icon_undefined from '../assets/icons_maps/icon_undefined.png'


interface CompProps {
  fileName: string
}


const ImgAvatar = styled.img.attrs(props => ({
  src: `${props.src}`
}))
  `
width: 60px;
height: 60px;
border-radius: 50%;
border: 1px solid red;
display: flex;`

const WidgetsIconVehicle: React.FC<CompProps> = ({ fileName }) => {

  const [image, setImage] = React.useState<string>()

  React.useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../assets/icons_maps/${fileName}.png`) // change relative path to suit your needs
        setImage(response.default)
      } catch (err) {
        console.log(err)
        setImage(icon_undefined)
      } finally {
        console.log("finally img")
      }
    }

    fetchImage()
  }, [fileName])

  return (
    <>
      <ImgAvatar src={image}></ImgAvatar>
    </>
  )

}
export default WidgetsIconVehicle