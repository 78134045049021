import React from 'react';
import { Column, SelectColumn } from 'react-data-grid';
import { httpAxiosV4 } from '../../gateways/Axios';
import DataGridComp from '../../components/datagridComp/DataGridComp';
import { styled } from 'styled-components';
import TopBarToolsCommands from './Components/TopBarToolsCommands';
import "../../styles/datagrid/datagrid.css"


const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 210px);
    overflow: auto;
`

const selectCellClassname = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > input {
    margin: 0;
  }
`;

interface iCommandsConfig {
    id: number;
    id_actions: number;
    model_equip: string;
    model_group: string;
    icon_config: string;
    title: string;
    descr: string;
    sitaxe_cmd: string;
    sitaxe_sms: string | null;
    label_btnsend: string | null;
    type_cmd: string;
    categ: string;
}



const CommandsCreatorPage: React.FC = () => {


    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<iCommandsConfig[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<iCommandsConfig[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<iCommandsConfig>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));


    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const [modalContent, setModalContent] = React.useState<string>('');

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const columns: Column<iCommandsConfig>[] = [
        {
            ...SelectColumn,
            headerCellClass: "selectCellClassname",
            cellClass: "selectCellClassname",
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80,
        },
        {
            key: 'id_actions',
            name: 'Cód',
            width: 80,
        },
        {
            key: 'model_equip',
            name: 'Modelo do Equipamento',
            width: 120,
        },
        {
            key: 'model_group',
            name: 'Grupo do Modelo',
            width: 120,
        },
        {
            key: 'icon_config',
            name: 'Ícone',
            width: 100
        },
        {
            key: 'title',
            name: 'Título',
            width: 100,
        },
        {
            key: 'descr',
            name: 'Descrição',
            width: 200,
        },
        {
            key: 'sitaxe_cmd',
            name: 'Sintaxe do Comando',
            width: 150,
        },
        {
            key: 'type_cmd',
            name: 'Tipo de Comando',
            width: 120,
        },
        {
            key: 'categ',
            name: 'Categoria',
            width: 100,
        },
    ]

    async function getDados() {
        try {
            const { data } = await httpAxiosV4<any, iCommandsConfig[]>(
                `getAllCmdConfig`,
                "GET"
            );
            setRows(data)
            setFilteredRows(data);
            setColumnsFilter(columns)
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {

        (async () => {

            getDados();
        })();
    }, []);

    return (
        <ContainerTable>

            {/*<TopBarToolsCommands
                filteredRows={filteredRows}
                rows={rows}
                selectedRows={selectedRows}
                columns={columns}
                handleSearch={function (): void {
                    throw new Error('Function not implemented.');
                }} onClickRefresh={function (): void {
                    throw new Error('Function not implemented.');
                }} handleSelectAction={function (): void {
                    throw new Error('Function not implemented.');
                }} />*/}
            {/*   <DataGridComp
                rows={filteredRows}
                columns={columns}
                visibleColumns={visibleColumns}
            />*/}


        </ContainerTable>
    );

}
export default CommandsCreatorPage