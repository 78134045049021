import styled from "styled-components";

export const LabelItem = styled.div`
    display: block;
    font-size: medium;
    color: #7e7e7e;
    width: 180px;
`
export const LabelTitle = styled.div`
    display: block;
    font-size: medium;
    color: #7e7e7e;
    width: 150px;
`
export const InfoItem = styled.div`
    display: flex;
    padding-top: 8px;
`
export const ValueItem = styled.div`
    display: block;
    margin-left: 10px;
    color: black;
`

export const ValueAlert = styled.div`
   display: block;
    margin-left: 10px;
    color: white;
    background: red;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
`
export const ValueItem2 = styled.div`
    display: block;
    margin-left: 10px;
    color: black;
    font-weight: 500;
    font-size: 20px;
`
export const Container = styled('div')({
    display: 'block',
    padding: 8
});

export const ContainerFlexInline = styled('div')({
    display: 'flex',
    justifyContent: 'initial',
    alignItems: 'center'
});
export const ContainerFlexCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px'
});

export const ContainerCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});


export const ContainerCard = styled.div`
    display: flex;
    align-items: center;
    padding: 18px;
    background: #ffffff;
    margin: 7px;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
`
export const ContainerBar = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    background: #ffffff;
`
export const ContainerButton = styled.button`
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;

`