

import React from 'react';
import GoogleMapV2 from './GoogleMapV2';
import { loadMapApi } from "../../utils/GoogleMapsScript";
import './PainelRight.css'
import { useSelector } from 'react-redux';
import { selectAppBar } from '../../redux/appBarSlice';
import AlertsPanelRight from './AlertsPanelRight';

const PanelRightComp: React.FC = () => {
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const { appbar } = useSelector(selectAppBar);

    React.useMemo(() => {
        (async () => {
            if (!scriptLoaded) {
                console.log("Loading map...")
                const googleMapScript = loadMapApi();
                googleMapScript.addEventListener('load', function () {
                    setScriptLoaded(true);
                });
            }
        })();
    }, [appbar.openMap]);

    return (
        <>
            <div className={`PainelBase ${appbar.openMap ? 'Opened' : ''}`}>
                {scriptLoaded && (
                    <GoogleMapV2 />
                )}
            </div>
            <div className={`PainelBaseAlerts ${appbar.openAlerts ? 'Opened' : ''}`}>
                <AlertsPanelRight />
            </div>
        </>
    )

}
export default PanelRightComp