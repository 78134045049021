import { Dialog, DialogTitle, DialogContent, Stack, DialogActions, Button, List, Avatar, ListItem, ListItemAvatar, ListItemText, Box, TextField, IconButton, Grid } from '@mui/material';
import { AddLocation, ShareLocation, Edit } from '@mui/icons-material'
import * as React from 'react';
import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import BarTable from '../../components/BarTable';
import { ctrlButtons, iDestinos, iDialogConfirme, iReduxMap, iPosition, iMsgAlert } from '../../interfaces/interfaces';

import http from '../../services/http-common'
import { selectUser } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import DialogConfirme from '../../components/DialogConfirme'
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';


import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as yup from 'yup';
import Input from '../../components/forms/components/InputComp';
import '../../styles/InputForm.css'
import '../../styles/global.css'

import { useRef } from 'react';
import LoadingCircularComp from '../../components/LoadingCircularComp';
import httpCommon from '../../services/http-common';
import { changeMenu, selectAppBar } from '../../redux/appBarSlice';
import { changeMap, resetMap, selectMap } from '../../redux/mapSlice';
import TextAreaComp from '../../components/forms/components/TextFieldComp';
import { ActionMaps } from '../../utils/values';
import { changeAlert } from '../../redux/alertServiceSlice';


const initialBtns: ctrlButtons = {
    RefreshShow: true,
    DeleteShow: true,
    DeleteDisable: true,
    addShow: true
}

const CtrlBtnsCad: ctrlButtons = {
    CancelShow: true,
    SaveShow: true
}

interface FormDataSave {
    id: number
    descr: string
    contact: string
    document: string
    ender: string
    email: string
    phone: string
    idempresa: number
    idcliente: number
    lat: number
    lng: number
}



const ListDestComp: React.FC = (props) => {


    const dispatch = useDispatch()
    const formRef = useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const { mapOptions } = useSelector(selectMap);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSaveLoading, setSaveLoading] = React.useState<boolean>(false);
    const [isShowCad, setShowCad] = React.useState<boolean>(false);
    const [listDialog, setListDialog] = React.useState<iDestinos[]>([]);
    const [listDatagrid, setListDatagrid] = React.useState<iDestinos[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [IsOpenDialogConfime, setOpenDialogConfirme] = React.useState<boolean>(false);
    const [ConfirmeDialogContent, setConfirmeDialogContent] = React.useState<iDialogConfirme>();
    const [positionMakerMap, setPositionMakerMap] = React.useState<iPosition | undefined>();
    const [formCadData, setformCadData] = React.useState<iDestinos | undefined>();


    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }


    async function getDados() {
        const result = await http.post("/GetListPlaces", {
            idcliente: user.idcliente,
            type: 1
        });
        let resp = result.data.dados;
        setListDialog(resp)
        setListDatagrid(resp)
        console.log(resp)
    }

    async function DeleteDestinos() {
        try {
            let obj = {
                iduser: user.iduser,
                idcliente: user.idcliente,
                idempresa: user.idempresa,
                rows: selectdRows
            }
            const result = await http.post("/setDelDestinos", obj);
            let resp = result.data;
            getDados()
            console.log(resp)
        } catch (error) {
            console.log(error)
        }
    }

    const columns: GridColDef[] = [

        {
            field: 'actions', headerName: 'Eventos', width: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => ShowInMapClick(params.row)}>
                            <ShareLocation />
                        </IconButton>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => EditItemClick(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 250,
        },
        {
            field: 'contact',
            headerName: 'Contato',
            width: 250
        },
        {
            field: 'document',
            headerName: 'Documento',
            width: 150
        },
        {
            field: 'ender',
            headerName: 'Endereço',
            width: 350,
        },
        {
            field: 'phone',
            headerName: 'Telefone',
            width: 130
        },
        {
            field: 'email',
            headerName: 'E-mail',
            width: 250
        }

    ];

    React.useEffect(() => {
        (async () => {
            console.log("useEffect ListDestComp")
            if (user.idcliente) { getDados() } else { console.log("cliente Invalid ListDestComp") }
        })();
    }, [user]);

    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
        CtrlBtns.DeleteDisable = i.length ? false : true
        setCtrlBtns(CtrlBtns)
    }

    const handleRefresh = () => {
        if (user.idcliente) { getDados() }
    }

    const handleDelete = () => {
        console.log(selectdRows)
        setConfirmeDialogContent({
            title: "Confirme Acão?",
            msg: "Deseja realmente apagar este ponto? , ele vai ser removido de todas as tarefas e rotas",
            icon: ""
        })
        setOpenDialogConfirme(true)
    };

    const handleCloseDialogConfirme = () => {
        setOpenDialogConfirme(false)
    };


    const CadNewPlaces = () => {
        setformCadData(undefined)
        setPositionMakerMap(undefined)
        dispatch(resetMap({}))
        setShowCad(true)
    };

    const handleCancelCad = () => {
        formRef.current?.reset()
        setPositionMakerMap(undefined)
        dispatch(resetMap({}))
        dispatch(changeMenu({ openMap: 0 }))
        setShowCad(false)
    }

    const handleSaveInServer = async (data: FormDataSave) => {
        setSaveLoading(true)
        data.idcliente = user.idcliente
        data.idempresa = user.idempresa
        const result = data.id ? await httpCommon.put("Places", data) : await httpCommon.post("Places", data)
        let resp = result.data;
        if (resp.IsSuccess) {
            handleSetMsgInfo({ msg: `Ponto ${data.id ? 'Alterado' : 'Criado'} com sucesso!`, type: 'success' })
        } else {
            handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            formRef.current?.setFieldError(resp.field, resp.msg)
        }
        if (resp.IsSuccess) {
            formRef.current?.reset()
            handleRefresh()
            handleCancelCad()
        }

    }

    const handleSubmitSaveCad: SubmitHandler<FormDataSave> = async data => {
        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                descr: yup.string().required("Esse campo é Obrigatório"),
                contact: yup.string(),
                document: yup.string(),
                ender: yup.string().required("Esse campo é Obrigatório"),
                email: yup.string().email("Digite um e-mail válido"),
                phone: yup.string(),

            });
            await schema.validate(data, {
                abortEarly: false,
            });
            console.log(typeof positionMakerMap)
            if (typeof positionMakerMap !== 'object') {
                formRef.current?.setFieldError('ender', "Precisa validar esse endereço no mapa")
                return
            }
            data.lat = positionMakerMap.lat
            data.lng = positionMakerMap.lng
            if (formCadData) {
                data.id = formCadData.id
            }
            handleSaveInServer(data)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }


    const ShowInMapClick = (item: iDestinos) => {
        dispatch(changeMenu({ openMap: new Date().getTime() }))

        let mapConfig: iReduxMap = {
            mapEvtClick: false,
            resetMap: false,
            action: ActionMaps.SHOW_MARKER,
            ender: item.ender,
            marker: {
                position: { lat: item.lat, lng: item.lng },
                isDragable: false
            }
        }
        dispatch(changeMap(mapConfig))
    }

    const EditItemClick = (item: iDestinos) => {
        console.log("Edit", item)
        setformCadData(item)
        setPositionMakerMap({ lat: item.lat, lng: item.lng })
        setShowCad(true)
    }

    const handleConfirmeDialogOK = () => {
        setOpenDialogConfirme(false)
        DeleteDestinos()
    };

    const handleOpenMap = () => {
        let ender = formRef.current?.getFieldValue('ender')
        let position = positionMakerMap


        dispatch(changeMenu({ openMap: 1 }))

        let mapConfig: iReduxMap = {
            mapEvtClick: true,
            resetMap: false,
            action: ActionMaps.CREATE_MARKER,
            ender: ender,
            marker: null
        }
        if (position) {
            mapConfig.marker = {
                position: position,
                isDragable: true
            }
        }
        dispatch(changeMap(mapConfig))
    }


    const handleResponseMap = () => {
        console.log(mapOptions.response)
        formRef.current?.setFieldValue('ender', mapOptions.response?.ender)
        setPositionMakerMap(mapOptions.response?.position)

    }


    React.useEffect(handleResponseMap, [mapOptions.response]);

    const cardNewPass = (
        <React.Fragment>

            <div className='form-box'>
                <Box
                    sx={{
                        height: 44,
                        padding: 1,
                        backgroundColor: '#ededed',
                    }} >
                    <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                        <BarTable CtrlButtons={CtrlBtnsCad} onSaveClick={() => formRef.current?.submitForm()} onCancelClick={handleCancelCad} />

                    </Stack>
                </Box>
                <div className='form-container2 margin-left'>
                    <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveCad} className='Input-Style' noValidate={true} initialData={formCadData}>
                        <div className='block-grid'>
                            <div className='subblock'>
                                <Input className='theme-light' name="descr" label='Descrição*' placeholder='Descrição' disabled={isLoading} role="presentation" type='text' />
                                <TextAreaComp rows={5} className='theme-light' name="ender" label='Endereço*' placeholder='Endereço' disabled={isLoading} role="presentation" />
                                <div>
                                    {positionMakerMap ? <i>Ponto validado</i> : <i>Utilize o botão abaixo para validar o ponto</i>}
                                    <button className="color-primary btn-icon" onClick={handleOpenMap} type="button">
                                        <AddLocation /> VER NO MAPA
                                    </button>
                                </div>
                            </div>
                            <div className='subblock'>
                                <Input className='theme-light' name="contact" label='Contato' placeholder='Contato' disabled={isLoading} role="presentation" type='text' />
                                <Input className='theme-light' name="document" label='Num Documento' placeholder='Num Documento' disabled={isLoading} role="presentation" type='text' />
                                <Input className='theme-light' name="phone" label='Telefone' placeholder='Telefone' disabled={isLoading} role="presentation" type='phone' />
                                <Input className='theme-light' name="email" label='E-mail' placeholder='E-mail' disabled={isLoading} role="presentation" type='email' />
                            </div>
                            {isLoading ? <LoadingCircularComp /> : <></>}
                        </div>
                    </Form>
                </div>
            </div>

        </React.Fragment>
    );



    const QuickSearchToolbar = () => {
        return (
            <>{cardNewPass}</>
        );
    }


    return <>
        <DialogConfirme open={IsOpenDialogConfime} onClose={handleCloseDialogConfirme} onConfirme={handleConfirmeDialogOK} content={ConfirmeDialogContent} />

        {isShowCad ? <>{QuickSearchToolbar()}</> :

            <WidgetDataGridComp
                isLoading={isLoading}
                rows={listDatagrid}
                columns={columns}
                CheckBoxSelect={true}
                handleSelRow={setSel}
                initialBtns={CtrlBtns}
                onRefresClick={handleRefresh}
                onAddClick={CadNewPlaces}
                onDeleteClick={handleDelete}
            />
        }

    </>

}

export default React.memo(ListDestComp)