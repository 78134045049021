import { httpAxiosV4 } from "../../../gateways/Axios";
import { itemChips, itemChipsCheck, reqChip, resResumeChips } from "../../../interfaces/types";
import { iProviderData } from "../Components/ProviderCardInfo";




export async function getResumeChips(payload: reqChip): Promise<resResumeChips> {
    const { data } = await httpAxiosV4<reqChip, resResumeChips>(
        `reqResumeChips`,
        "POST",
        payload
    );
    return data;
}
export async function getListChips(payload: reqChip): Promise<itemChips[]> {
    const { data } = await httpAxiosV4<reqChip, itemChips[]>(
        `reqListChips`,
        "POST",
        payload
    );
    return data;
}
export async function getListChipSimple(payload: reqChip): Promise<itemChipsCheck[]> {
    const { data } = await httpAxiosV4<reqChip, itemChipsCheck[]>(
        `reqListChipSimple`,
        "POST",
        payload
    );
    return data;
}
export async function removerEquip(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqChip, itemChipsCheck[]>(
        `remove-equip`,
        "POST",
        payload
    );
    return data;
}
export async function alterApn(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, unknown>(
        `alter-apn`,
        "POST",
        payload
    );
    return data;
}

export async function getInfoProvider(idapn: number): Promise<iProviderData> {
    const { data } = await httpAxiosV4<reqChip, any>(
        `infoProviderApn?idapn=${idapn}`,
        "GET"
    );
    return data;
}

export async function saveChip(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqChip, any>(
        `save-chip`,
        "POST",
        payload
    );
    return data;
}
export async function updateChip(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqChip, any>(
        `save-chip`,
        "PUT",
        payload
    );
    return data;
}
export async function SetEquipInChip(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqChip, any>(
        `SetEquipInChip`,
        "PUT",
        payload
    );
    return data;
}
export async function deleteChips(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqChip, any>(
        `deleteChips`,
        "DELETE",
        payload
    );
    return data;
}

