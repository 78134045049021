
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux';
import { changeMenu, selectAppBar } from '../../redux/appBarSlice';
import useWindowDimensions from '../../hooks/DimensionHook';
import styled from 'styled-components';
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';
import { ctrlButtons } from '../../interfaces/interfaces';
import { GridColDef } from '@mui/x-data-grid';
import avatar from '../../assets/icons/SleepOrange.png'
import httpApiV3 from '../../services/http-xsmart-apiv3';
import httpCommon from '../../services/http-common';
import { itemAlerts } from '../../interfaces/types';
import { selectUser } from '../../redux/userSlice';
import './timeline.css'
import { DateFormatter, TimeFormatter } from '../../utils/format';
import { Box, Stack } from '@mui/material';
import BarTable from '../BarTable';
import DialogSettingsAlerts from '../Modal/DialogSettingsAlerts';
import { getDateTimeEnd, getDateTimeStart, getLast30Days } from '../../utils/Converter';

const Container = styled('div')({
    background: '#f6f9ff',
    height: '40px',
    border: '1px solid'
});


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '4px',
    //height: '450px',
    overflow: 'auto'
});


const CtrlBtnsInit: ctrlButtons = {
    CancelShow: true,
    SettingsShow: true,
    RefreshShow: true,
    FilterShow: false,
    SearchBarShow: false
}

const ImgBox = styled.img`
    margin: 0 auto;
    height: 36px;
`


const AlertsPanelRight: React.FC = () => {

    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const { user } = useSelector(selectUser);
    const { appbar } = useSelector(selectAppBar);
    const [StylePanel, setStylePanel] = useState({});
    const { height, width } = useWindowDimensions();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<itemAlerts[]>([]);
    const [CtrlBtns, setCtrlBtns] = useState<ctrlButtons>(CtrlBtnsInit);
    const [isOpen, setIsOpen] = useState<boolean>(false);


    function DimensionMap() {
        let widthObj = width - 720
        let heightObj = height - 55
        if (widthObj > 720) widthObj = 720
        if (widthObj < 720) widthObj = 720
        setStylePanel({
            width: widthObj + "px",
            height: heightObj + "px",
            background: '#f6f9ff',
            border: '1px solid',
            overflow: 'auto'
        })
    }

    useEffect(() => {
        DimensionMap()
    }, [height, width]);

    useEffect(() => {
        if (appbar.openAlerts) {
            DimensionMap();
            GetDados()
        }
    }, [appbar.openAlerts])



    useLayoutEffect(() => {
        console.log("useLayoutEffect googlemaps")
        if (ref.current) {
            setStylePanel({
                height: ref.current.offsetHeight + "px"
            })
        }
    }, [])


    const GetDados = async () => {
        let payload = {
            "idcliente": user.idcliente,
            "iduser": user.iduser,
            "nivel": user.nivel,
            "status": "1",
            "dtStart": getLast30Days(),
            "dtEnd": getDateTimeEnd(),
            "idveiculo": 0,
            "idmotorista": 0,
            "alertype": "0",
            "priority": "0",
            "typenotif": "0"
        }

        const result = await httpCommon.post("getNotifByFilter", payload)
        let resp = result.data.dados;
        setListDatagrid(resp)
    }


    const handleCloseMap = () => {
        dispatch(changeMenu({ openAlerts: 0 }))
    }
    const handleSettings = () => {
        handleOpenSettings(true)
        dispatch(changeMenu({ openAlerts: 0 }))
    }
    const handleOpenSettings = (item: boolean) => {
        setIsOpen(item)
    }

    const handleSearchMap = (val: string) => {
        if (val.length > 0) {
            console.log(val)
        }
    }

    return (
        <div style={StylePanel}>
            <DialogSettingsAlerts onClose={handleOpenSettings} open={isOpen} />
            <Box
                sx={{
                    height: 44,
                    padding: 1,
                    backgroundColor: '#ededed',
                }} >
                <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                    <BarTable CtrlButtons={CtrlBtns} onCancelClick={handleCloseMap} onSearchValue={handleSearchMap} onSettingsClick={handleSettings} />

                </Stack>
            </Box>
            <ContainerTable>
                {listDatagrid.length > 0 && <ul className="cbp_tmtimeline" >
                    <>{listDatagrid.map((item: itemAlerts) => {
                        let img = ''
                        let urlImg = item.tipo === 'CERCA' ? `icons_flags/${item.avatar}` : `icons_alerts/${item.avatar}`
                        try {
                            img = require(`../../assets/${urlImg}`)
                        } catch (error) {
                            img = require(`../../assets/icons_alerts/cancel_1.png`)
                        }
                        return <li>
                            <time className="cbp_tmtime">
                                <span>{TimeFormatter(item.dtevt)}</span>
                                <span>{DateFormatter(item.dtevt)}</span>
                                {item.status && <span className="labelNew" >NOVO</span>}
                            </time>
                            <div className="cbp_tmicon bgg-none"><ImgBox src={img} alt={item.title} /></div>
                            <div className="cbp_tmlabel">
                                <div className="cbp_tmmap">
                                    {item.points > 0 && <span className="labelinfo35 mRight5px" ng-className="item.priorityClass"
                                        ng-show="item.points > 0">{item.points}p</span>}
                                    {item.typenotif === 'WARNING' && <span className="labelinfo colorYellow mRight5px">AVISO</span>}
                                    {item.typenotif === 'EVT' && <span className="labelinfo colorYellow mRight5px">AVISO</span>}
                                    {item.typenotif === 'INFRA' && <span className="labelinfo colorRed mRight5px">INFRAÇÃO</span>}
                                    {item.priority === 1 && <span className="labelinfo colorYellow mRight5px">Baixa</span>}
                                    {item.priority === 2 && <span className="labelinfo colorOrange mRight5px">Média</span>}
                                    {item.priority === 3 && <span className="labelinfo colorRed mRight5px">Alta</span>}
                                </div>
                                <div className="titleAlert" >{parse(item.title)}</div>
                                <div className="descrAlert" >{parse(item.descr)}</div>

                                <p>{item.obs}</p>
                                <div className="cbp_tmuser2">
                                    <i>Regra: {item.ruledescr}</i>
                                </div>
                                <div className="cbp_tmuser">
                                    <i className='mRight5px'>{item.veiculo_placa}</i>
                                    <i >{item.DriverName}</i>
                                </div>
                            </div>

                        </li>

                    })}</>
                </ul>}
                {!listDatagrid.length && <div>Sem Registro</div>}
            </ContainerTable>
        </div>
    );
};

export default AlertsPanelRight;
