import React, { useState, ChangeEvent, FormEvent } from 'react';
import ViewListDrivers, { itemDriver } from '../Components/ViewListDrivers';
import httpServerFiles from '../../../services/http-server-files';
import ViewDetailsDriver from '../Components/ViewDetailsDriver';
import CadDriverForms from '../Forms/CadDriverForms';

interface CompProps {
    item: string
}

const DriversPage: React.FC<CompProps> = ({ item }) => {

    const [DriverSelected, setDriverSelected] = useState<itemDriver>();
    const [isShowFormDriver, setShowFormDriver] = useState<boolean>(false);
    const [isShowDetailsDriver, setShowDetailsDriver] = useState<boolean>(false);

    const openFormNewDriver = () => {
        setDriverSelected(undefined)
        setShowFormDriver(true)
    }
    const openFormEditDriver = (item: itemDriver) => {
        setDriverSelected(item)
        setShowFormDriver(true)
    }
    const handleUpdateForm = () => {
        setDriverSelected(undefined)
        setShowFormDriver(false)
    }
    const handleOpenDetails = (item: itemDriver) => {
        setDriverSelected(item)
        setShowDetailsDriver(true)
    }
    const handleCloseDetails = () => {
        setDriverSelected(undefined)
        setShowDetailsDriver(false)
    }

    return (
        <>
            {!isShowFormDriver &&
                <div>
                    {isShowDetailsDriver && DriverSelected !== undefined ?
                        <ViewDetailsDriver driver={DriverSelected} onCloseDetails={handleCloseDetails} />
                        : <ViewListDrivers openDetails={handleOpenDetails} cadNewDriver={openFormNewDriver} cadEditDriver={openFormEditDriver} />}
                </div>}
            {isShowFormDriver && <CadDriverForms initialData={DriverSelected} onCloseForm={() => { setShowFormDriver(false) }} onCloseFormUpdate={handleUpdateForm} />}
        </>
    )

}
export default DriversPage