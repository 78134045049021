
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import DriversDialog from '../DriversDialog';
import { iDrivers } from '../../interfaces/interfaces';
import httpCommon from '../../services/http-common';


interface iPropsDriver {
    idMotorista?: number
    selectDriver?: (item: iDrivers) => void
    clearSelDriver?: () => void
}


const Box = styled('div')({
    marginLeft: '5px',
    margin: '10px'
});

const Container = styled('div')({
    display: 'flex',
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '5px',
    maxWidth: '350px',
    minHeight: '100px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)'

});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '270px'

});

const ContainerBlock = styled('div')({
    display: 'block',
    justifyContent: 'space-between'
});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase'
});

const OnClickPointer = styled('div')({
    cursor: 'pointer'
});

const CardDriverWidget: React.FC<iPropsDriver> = (props) => {

    const [Driver, SetDriver] = React.useState<iDrivers>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (item: iDrivers) => {
        handleClose()
        SetDriver(item)
        if (props.selectDriver) { props.selectDriver(item) }
    };

    const handleClearSelect = () => {
        SetDriver(undefined)
        if (props.clearSelDriver) props.clearSelDriver()
    };

    const handleGetInfoDriver = async () => {
        const result = await httpCommon.get("getDriverInfo", { params: { id: props.idMotorista } })
        let resp = result.data;
        handleSelect(resp)
    }


    React.useEffect(() => {
        if (props.idMotorista) {
            handleGetInfoDriver()
        }
    }, [props.idMotorista])

    return (
        <>
            {<DriversDialog onClose={handleClose} open={IsOpen} onMultiSelect={false} handleSelect={handleSelect} />}
            <Box>
                <TextApelido>Motorista/Colaborador</TextApelido>
                {Driver?.id ? <><Container onClick={() => handleClickOpen()}>
                    <Avatar src="/broken-image.jpg" />
                    <ContainerInfo>
                        <Title>{Driver.DriverName}</Title>
                        <ContainerBlock>
                            <Subtitle>Cód: {Driver.id}</Subtitle>
                            <Subtitle>Tel.: {Driver.tel}</Subtitle>
                        </ContainerBlock>
                    </ContainerInfo>
                </Container>
                    <ContainerInfo>
                        <Subtitle><OnClickPointer onClick={handleClearSelect}>❌ Remover Seleção</OnClickPointer></Subtitle>
                    </ContainerInfo>
                </>
                    : <>
                        <Container onClick={() => handleClickOpen()}>
                            <Avatar src="/broken-image.jpg" />
                            <ContainerInfo>
                                <Subtitle>Selecione um Motorista/Colaborador</Subtitle>
                            </ContainerInfo>
                        </Container>
                    </>}
            </Box>

        </>
    )

}
export default CardDriverWidget