import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DriversComp from '../../../components/DriversComp';
import ListDest from '../../../components/ListDest';
import VehiclesComp from '../../../components/VehiclesComp';
import DialogListDest from '../../../components/DialogListDest';
import { ctrlBarUtilsButtons, iDestinos, iDrivers, iGroupRouter, iMarker, iMsgAlert, iMsgSnack, iPlaces, iPolyline, iReduxMap, iReqRegisterGroupRouter, iRoutes, iTaskReq, iVehicle } from '../../../interfaces/interfaces';
import PlacesComp from '../../../components/PlacesComp';
import httpCommon from '../../../services/http-common';
import httpModuleAPI from '../../../services/http-module-api';
import httpOsrAPI from '../../../services/http-osr-api';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import ShortUniqueId from 'short-unique-id';
import '../../../components/CompStyles.css'
import BarUtils from '../../../components/BarUtils';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import { changeMap, selectMap } from '../../../redux/mapSlice';
import { changeAlert } from '../../../redux/alertServiceSlice';
import { changeMenu } from '../../../redux/appBarSlice';
import { ActionMaps } from '../../../utils/values';

import iconFlagStart from '../../../assets/icons/flag_start32.png'
import iconFlagEnd from '../../../assets/icons/flag_fim32.png'
import { iItemRouter, iRouter } from '../deliveryInterfaces';
import httpApiV3 from '../../../services/http-xsmart-apiv3';
import { adicionarEscape, removerEscape } from '../../../utils/format';

const initialBtns: ctrlBarUtilsButtons = {
    CancelShow: true,
    SaveShow: true
}

interface PropsComp {
    onCloseCad: () => void
    itemRouter?: iItemRouter
}

const gRouterInit: iItemRouter = {
    DriverAvatar: '',
    DriverName: '',
    descr: '',
    distance: 0,
    dtcad: '',
    duraction: 0,
    id: 0,
    idmotorista: 0,
    idvehicle: 0,
    polyline: '',
    status: undefined,
    veiculo_placa: '',
    id_point_start: 0,
    descr_point_start: '',
    lat_point_start: 0,
    lng_point_start: 0,
    id_point_end: 0,
    descr_point_end: '',
    lat_point_end: 0,
    lng_point_end: 0
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CadTaskComp: React.FC<PropsComp> = (props) => {

    const uid = new ShortUniqueId({
        dictionary: 'alpha_upper',
        length: 4
    });

    const uidn = new ShortUniqueId({
        dictionary: 'number',
        length: 4
    });

    const dispatch = useDispatch()
    const { user } = useSelector(selectUser);
    const { mapOptions } = useSelector(selectMap);
    const [rows, setRows] = useState<iDestinos[]>([]);
    const [localiza, setLocaliza] = React.useState<iMarker[]>([])
    const [bounds, setBounds] = React.useState<google.maps.LatLngBounds | null>(null)
    const [centerMap, setCenterMap] = React.useState<google.maps.LatLng | null>(null)
    const [IsOpenSelectDest, setOpenSelectDest] = React.useState<boolean>(false);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlBarUtilsButtons>(initialBtns);
    const [VehicleItem, setVehicle] = React.useState<iVehicle>()
    const [DriverItem, setDriver] = React.useState<iDrivers>()
    const [startPoint, setStartPoint] = React.useState<iDestinos>()
    const [endPoint, setEndPoint] = React.useState<iDestinos>()
    const [RouterItem, setRouter] = React.useState<iRouter>()
    const [GroupRouter, setGroupRouter] = React.useState<iItemRouter>(gRouterInit)
    const [IsOpenLoadBack, setOpenLoadBack] = React.useState(false);



    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }



    const HandleCenterMap = (item: any) => {
        let center = new window.google.maps.LatLng({ lat: item.lat, lng: item.lng })
        setCenterMap(center)
        console.log(item)
    };


    const handleClickOpen = () => {
        setOpenSelectDest(true);
    };

    const handleCloseSelectDest = () => {
        setOpenSelectDest(false);
    };


    const handleSelectList = (itens: iDestinos[]) => {
        handleCloseSelectDest()
        let _rows = [...rows, ...itens]
        let res = _rows.map((item, idx) => {
            item.order_item = idx + 1
            return item
        })
        setRows(res)
    };

    const handleClickDelete = (ids: number[]) => {
        let res = rows.filter(item => !ids.includes(item.id));
        setRows(res)
    };


    const handleSelVehicle = (item: iVehicle) => {
        setVehicle(item)
    };

    const handleSelDriver = (item: iDrivers) => {
        setDriver(item)
    };

    const handleSelStartPoint = (item: iDestinos) => {
        setStartPoint(item)
    };

    const handleSelEndPoint = (item: iDestinos) => {
        setEndPoint(item)
    };


    const handleClickMap = () => {
        let mks: iMarker[] = []
        let startP: iMarker = {
            position: { lat: Number(startPoint?.lat), lng: Number(startPoint?.lng) },
            isDragable: false,
            label: "I",
            icon: iconFlagStart
        }
        mks.push(startP)
        let endP: iMarker = {
            position: { lat: Number(endPoint?.lat), lng: Number(endPoint?.lng) },
            isDragable: false,
            label: "F",
            icon: iconFlagEnd
        }
        mks.push(endP)
        rows.forEach(element => {
            mks.push({
                position: { lat: element.lat, lng: element.lng },
                isDragable: false,
                label: element.order_item?.toString()
            })
        });
        console.log(mks)
        dispatch(changeMenu({ openMap: new Date().getTime() }))

        let mapConfig: iReduxMap = {
            action: ActionMaps.SHOW_ROUTER,
            ender: '',
            markers: mks,
            polyline: GroupRouter?.polyline
        }
        dispatch(changeMap(mapConfig))
    };





    const handleClickRouter = async () => {
        console.log("router")

        if (!VehicleItem?.id) {
            handleSetMsgInfo({ msg: "Veículo não selecionado!", type: 'error' })
            return
        }
        if (!DriverItem?.id) {
            handleSetMsgInfo({ msg: "Colaborador não selecionado!", type: 'error' })
            return
        }
        if (!rows.length) {
            handleSetMsgInfo({ msg: "Nenhum ponto selecionado!", type: 'error' })
            return
        }
        if (!startPoint?.lat) {
            handleSetMsgInfo({ msg: "Ponto Inicial não selecionado", type: 'error' })
            return
        }
        if (!endPoint?.lat) {
            handleSetMsgInfo({ msg: "Ponto Final não selecionado", type: 'error' })
            return
        }
        setOpenLoadBack(true)
        let vehicles = [{
            id: VehicleItem?.id,
            profile: "driving-car",
            capacity: [100],
            skills: [1],
            description: VehicleItem?.placa,
            start: [Number(startPoint?.lng), Number(startPoint?.lat)],
            end: [Number(endPoint?.lng), Number(endPoint?.lat)]
        }]
        let tasks = rows.map((item => {
            let obj = {
                id: item.id,
                description: "Pedido " + item.descr,
                setup: 0,
                service: 300,
                delivery: [1],
                skills: [1],
                priority: 0,
                location: [item.lng, item.lat]
            }
            return obj
        }))
        let obj = {
            vehicles: vehicles,
            tasks: tasks
        }
        try {
            let resp = await httpOsrAPI.post("/getRoutes", obj)
            let data = resp.data
            let routes: iRoutes[] = data.steps
            let _rows = rows;
            for (let x = 0; x < routes.length; x++) {
                const element = routes[x];
                if (element.type === 'job') {
                    for (let z = 0; z < _rows.length; z++) {
                        if (_rows[z].id === element.id) {
                            _rows[z].order_item = x
                            break
                        }
                    }
                }
            }
            setRows(_rows)
            /*setRouter({
                polyline: data.geometry,
                distance: data.distance / 1000,
                duraction: data.duration
            })*/
            setGroupRouter({ ...GroupRouter, polyline: data.geometry, distance: data.distance / 1000, duraction: data.duration })
            setOpenLoadBack(false)
            handleSetMsgInfo({ msg: "Roteirização finalizada", type: 'success' })

        } catch (error) {
            setOpenLoadBack(false)
            console.log(error)
        }
    };


    async function getTasksRouter(idrouter: number) {
        const result = await httpApiV3.post("/ListTasksByRouter", {
            idrouter: idrouter
        });
        let resp = result.data;
        setRows(resp)
        console.log(resp)
    }

    const handleSave = async () => {
        console.log("handleSave")
        try {
            if (!VehicleItem?.id) {
                handleSetMsgInfo({ msg: "Veículo não selecionado!", type: 'error' })
                return
            }
            if (!DriverItem?.id) {
                handleSetMsgInfo({ msg: "Colaborador não selecionado!", type: 'error' })
                return
            }
            if (!rows.length) {
                handleSetMsgInfo({ msg: "Nenhum ponto selecionado!", type: 'error' })
                return
            }
            setOpenLoadBack(true)
            let gRouter: iGroupRouter = {
                id: GroupRouter.id,
                idclient: user.idcliente,
                idempresa: user.idempresa,
                idvehicle: VehicleItem?.id,
                descr: uid.rnd(),
                idmotorista: DriverItem?.id,
                route_status: 'WAIT',
                distance: GroupRouter.distance,
                duraction: GroupRouter.duraction,
                polyline: adicionarEscape(GroupRouter.polyline),
                id_point_start: startPoint?.id ?? -1,
                descr_point_start: startPoint?.descr ?? 'Err',
                lat_point_start: startPoint?.lat ?? -1,
                lng_point_start: startPoint?.lng ?? -1,
                id_point_end: endPoint?.id ?? -1,
                descr_point_end: endPoint?.descr ?? 'Err',
                lat_point_end: endPoint?.lat ?? -1,
                lng_point_end: endPoint?.lng ?? -1,
            }



            let tasks: iTaskReq[] = []

            for (const item of rows) {
                tasks.push({
                    iddestino: item.id,
                    order_item: item.order_item ?? 0,
                    nf: ''
                })
            }


            let payload: iReqRegisterGroupRouter = {
                GroupRouter: gRouter,
                iduser: user.iduser,
                tasks
            }
            console.log(payload)
            let resp = GroupRouter.id > 0 ? await httpApiV3.put("/GroupRouter", payload) : await httpApiV3.post("/GroupRouter", payload)
            console.log(resp)
            setOpenLoadBack(false)
            handleSetMsgInfo({ msg: "Roteiro salvo com sucesso!", type: 'success' })
            setTimeout(() => {
                props.onCloseCad()
            }, 2000);

        } catch (error) {
            setOpenLoadBack(false)
            handleSetMsgInfo({ msg: "falha ao salvar roteiro", type: 'error' })
        }

    }

    const handleCancel = () => {
        props.onCloseCad()
    }

    const handleCloseLoadBack = () => {
        setOpenLoadBack(false)
    }
    const handleClickRefresh = () => {
    }


    React.useEffect(() => {
        const { itemRouter } = props
        console.log(itemRouter)
        if (itemRouter) {
            getTasksRouter(props.itemRouter?.id ?? 0)
            setVehicle({
                id: itemRouter.idvehicle,
                placa: itemRouter.veiculo_placa
            })
            setDriver({
                id: itemRouter.idmotorista,
                DriverName: itemRouter.DriverName,
                DriverAvatar: itemRouter.DriverAvatar
            })
            setStartPoint({
                id: itemRouter.id_point_start,
                descr: itemRouter.descr_point_start,
                lat: itemRouter.lat_point_start,
                lng: itemRouter.lng_point_start,
            })
            setEndPoint({
                id: itemRouter.id_point_end,
                descr: itemRouter.descr_point_end,
                lat: itemRouter.lat_point_end,
                lng: itemRouter.lng_point_end,
            })
            if (itemRouter.polyline.length > 0) {
                setGroupRouter({ ...itemRouter, polyline: removerEscape(itemRouter.polyline) })
            } else {
                setGroupRouter(itemRouter)
            }
        }
    }, [props.itemRouter])


    return (
        <>
            <LoadingBackdrop open={IsOpenLoadBack} onClose={handleCloseLoadBack} />
            <DialogListDest onClose={handleCloseSelectDest} open={IsOpenSelectDest} onSave={handleSelectList} onMultiSelect={true} />
            <div className='BarTop'>
                <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
                    <VehiclesComp handleSelVehicle={handleSelVehicle} VehicleSel={VehicleItem} />
                    <DriversComp handleSelDriver={handleSelDriver} DriverSel={DriverItem} />
                    <PlacesComp handleSelPoint={handleSelStartPoint} title={'Ponto Inicial'} PlaceSel={startPoint} />
                    <PlacesComp handleSelPoint={handleSelEndPoint} title={'Ponto Final'} PlaceSel={endPoint} />
                    <BarUtils CtrlButtons={CtrlBtns} onCancelClick={handleCancel} onSaveClick={handleSave} />
                </Stack>
            </div>
            <Grid container spacing={1}>
                <ListDest list={rows}
                    onCenterMap={HandleCenterMap}
                    onClickOpenDialog={handleClickOpen}
                    handleClickDelete={handleClickDelete}
                    handleClickRouter={handleClickRouter}
                    handleClickRefresh={handleClickRefresh}
                    handleClickMap={handleClickMap} />

            </Grid>
        </>
    )

}
export default CadTaskComp