import React, { useEffect, useState } from 'react';
import http from '../../services/http-common';
import ModuleAPI from '../../services/http-module-api';

import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarDensitySelector, GridEventListener } from '@mui/x-data-grid';
import { Box, IconButton, Avatar, Stack } from '@mui/material';
import { Edit, FileOpen } from '@mui/icons-material';
import BarUtils from '../../components/BarTable';
import CadTaskComp from './Components/CadTasksComp';
import { ctrlButtons, iDialogConfirme } from '../../interfaces/interfaces';
//import { selectUser } from '../redux/userSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { selectUser } from '../../redux/userSlice';
import DialogConfirme from '../../components/DialogConfirme';
import { iItemRouter } from './deliveryInterfaces';
import httpApiV3 from '../../services/http-xsmart-apiv3';
import httpApiV4 from '../../services/http-xsmart-apiv4';
import axios from 'axios';




const initialBtns: ctrlButtons = {
    addShow: true,
    RefreshShow: true,
    DeleteShow: true,
    DeleteDisable: true
}

const RoutesComp: React.FC = () => {

    const { user } = useSelector(selectUser);

    const [rows, setRows] = useState<iItemRouter[]>([]);
    const [ItemRouterEdit, setItemRouter] = useState<iItemRouter>();
    const [isShowCad, setShowCadCom] = React.useState<boolean>(false);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [IsOpenDialogConfime, setOpenDialogConfirme] = React.useState<boolean>(false);
    const [ConfirmeDialogContent, setConfirmeDialogContent] = React.useState<iDialogConfirme>();

    async function getDados() {
        try {
            const obj = {
                idclient: user.idcliente
            }
            const result = await httpApiV3.get("/ListGroupRouterByClient", { params: obj });
            let resp = result.data;
            setRows(resp)
        } catch (error) {
            console.log(error)
        }
    }


    async function DeleteRoutes() {
        try {
            let obj = {
                iduser: user.iduser,
                idcliente: user.idcliente,
                idempresa: user.idempresa,
                rows: selectdRows
            }
            const result = await http.post("/setDelRouters", obj);
            let resp = result.data;
            getDados()
            console.log(resp)
        } catch (error) {
            console.log(error)
        }
    }

    const EditItemClick = (item: iItemRouter) => {
        console.log("Edit Router", item)
        setItemRouter(item)
        setShowCadCom(true)

    }


    const columns: GridColDef[] = [
        {
            field: 'Actions', headerName: 'Ações', width: 120,
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="add to shopping cart" onClick={e => openRowClick(params.row)}>
                            <FileOpen />
                        </IconButton>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => EditItemClick(params.row)}>
                            <Edit />
                        </IconButton>
                    </>
                );
            }


        },
        {
            field: 'id',
            headerName: 'Cód Rota',
            width: 120,
        },
        {
            field: 'route_status',
            headerName: 'Status',
            width: 150,
        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width: 150,
        },
        {
            field: 'veiculo_placa',
            headerName: 'Placa',
            width: 150,
        },
        {
            field: 'DriverName',
            headerName: 'Colaborador',
            width: 250,
        },
        {
            field: 'distance',
            headerName: 'Distância',
            type: "number",
            width: 110,
        },
        {
            field: 'duraction',
            headerName: 'Duração',
            type: "number",
            width: 110,
        },
    ];

    useEffect(() => {
        if (user.idcliente) { getDados() }
    }, [user])



    const openRowClick = async (params: any) => {
        console.log(params)
        const obj: any = {
            idrouter: params.id,
            iduser: user.iduser
        }
        const queryString = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
        const url = `${process.env.REACT_APP_REPORT_API}/getRouter?${queryString}`;
        window.open(url, '_blank');
    };


    const onClickAdd = () => {
        setItemRouter(undefined)
        setShowCadCom(true)
    };

    const handleCloseCad = () => {
        console.log("handleCancelCad")
        setShowCadCom(false)
        getDados()
    };

    const handleSelRow = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
        CtrlBtns.DeleteDisable = i.length ? false : true
        setCtrlBtns(CtrlBtns)
    };

    const handleRefresh = () => {
        if (user.idcliente) { getDados() }
    }

    const handleDelete = () => {
        console.log(selectdRows)
        setConfirmeDialogContent({
            title: "Confirme Apagar?",
            msg: "Deseja realmente apagar este roteiro e todos suas tarefas?",
            icon: ""
        })
        setOpenDialogConfirme(true)
    };

    const handleCloseDialogConfirme = () => {
        setOpenDialogConfirme(false)
    };

    const handleConfirmeDialogOK = () => {
        setOpenDialogConfirme(false)
        DeleteRoutes()
    };



    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    height: 64,
                    padding: 1,
                    backgroundColor: '#ededed',
                }} >
                <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                    <GridToolbarColumnsButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarQuickFilter />
                    <BarUtils CtrlButtons={CtrlBtns} onAddClick={onClickAdd} onDeleteClick={handleDelete} onRefresClick={handleRefresh} />
                </Stack>
            </Box>
        );
    }

    return (
        <>
            <DialogConfirme open={IsOpenDialogConfime} onClose={handleCloseDialogConfirme} onConfirme={handleConfirmeDialogOK} content={ConfirmeDialogContent} />
            {isShowCad ? <Box sx={{ height: '92vh', width: '100%', background: 'white' }}>
                <CadTaskComp onCloseCad={handleCloseCad} itemRouter={ItemRouterEdit} />
            </Box> :
                <div className='max-box-datagrid'>
                    <DataGrid
                        rows={rows}
                        columns={columns}


                        checkboxSelection={true}
                        disableRowSelectionOnClick

                        className={'tables'}
                        components={{ Toolbar: QuickSearchToolbar }}
                        onRowSelectionModelChange={(newSelectionModel: any) => {
                            handleSelRow(newSelectionModel)
                        }}
                    />
                </div>}
        </>
    )

}
export default RoutesComp

