

import React from 'react';
import styled from 'styled-components';

import icon_undefined from '../assets/icons_maps/icon_undefined.png'
import axios from 'axios';


interface CompProps {
  fileName: string
}


const ImgAvatar = styled.img.attrs(props => ({
  src: `${props.src}`
}))
  `
width: 60px;
height: 60px;
border-radius: 50%;
border: 1px solid red;
display: flex;`

const WidgetsAvatarDriver: React.FC<CompProps> = ({ fileName }) => {

  const [image, setImage] = React.useState<string>()

  React.useEffect(() => {
    const imageUrl = `${process.env.REACT_APP_FILES_SERVER}/avatar-driver/${fileName}`
    axios.head(imageUrl)
      .then(() => {
        setImage(imageUrl);
      })
      .catch((error) => {
        setImage(icon_undefined)
      })
  }, [fileName])

  return (
    <>
      <ImgAvatar src={image}></ImgAvatar>
    </>
  )

}
export default WidgetsAvatarDriver