// config.js

// Configurações padrão para Google Maps
export const DEFAULT_WIDTH_SUB_GOOGLEMAPS = 800;
export const DEFAULT_HEIGHT_SUB_GOOGLEMAPS = 200;

// Cores de fundo
export const BACKGROUND_COLOR = '#f0f0f0';
export const HEADER_BACKGROUND_COLOR = '#333333';

// Cores dos componentes
export const BUTTON_COLOR = '#007bff';
export const BUTTON_HOVER_COLOR = '#0056b3';