

import React, { useEffect, useState } from 'react';
import { reqChip, resResumeChips, itemChips } from '../../../interfaces/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { SetEquipInChip, alterApn, deleteChips, getListChips, getResumeChips, removerEquip } from '../Gateways/chipsGateway';
import CardTotal from '../../../components/CardTotal';
import styled from 'styled-components';
import { ctrlButtons, iMsgAlert } from '../../../interfaces/interfaces';
import { changeAlert } from '../../../redux/alertServiceSlice';
import ChipsCadForm from './ChipsCadForm';
import { Column, SelectColumn } from 'react-data-grid';
import SearchField from '../../../components/datagridComp/SearchField';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import ColumnSelect from '../../../components/datagridComp/ColumnSelect';
import ModalComp from '../../../components/datagridComp/ModalComp';
import ChipProvidersSelect, { IChipProvider } from '../Components/ChipProvidersSelect';
import LoadingIndicator from '../../../components/datagridComp/LoadingComp';
import ConfirmationDialog, { typeMethods } from '../../../components/datagridComp/ConfirmationDialog';
import ChipEquipSelect, { IEquipList } from '../Components/ChipEquipSelect';
import SmallButton from '../../../components/datagridComp/SmallButton';
import "../../../styles/datagrid/datagrid.css"
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import ButtonAdd from '../../../components/datagridComp/ButtonAdd';
import ButtonEdit from '../../../components/datagridComp/ButtonEdit';


const ContainerViewTable = styled.div`
    display: grid;
  grid-template-rows: 85px 1fr; /* Define as alturas das linhas */
  grid-gap: 5px; /* Define o espaçamento entre as linhas */
  height: 85vh; /* Define a altura do contêiner */
`
const ContainerFlexInline = styled.div`
    display: flex;
    justify-content: center; 
    overflow: auto;   
`
const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 210px);
    overflow: auto;
`
const ContainerBarTop = styled.div`
padding: 6px;
display: flex;
    justify-content: space-between;
`
const ContainerSearch = styled.div`
padding: 6px;
display: flex;
flex-direction: column;
    justify-content: space-between;
    > input {
        margin: 0;
        padding: 8px;
        border: 1px solid #7F7F7F;
        border-radius: 7px;
        width: 250px;
  }
`
const ContainerDataGrid = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    block-size: 65vh;
    padding: 8px;
    contain: inline-size;

`

const selectCellClassname = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > input {
    margin: 0;
  }
`;

const sortPriorityClassname = styled.div`
  color: grey;
  margin-left: 2px;
`;

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    addShow: true
}


type filterSelected = {
    allRecords: boolean
    allStock: boolean
    stockChips: boolean
    stockEquipOnline: boolean
    stockChipWithEquip: boolean
    allInstaled: boolean
    instaledOnline: boolean
    instaledOffline: boolean
    instaledPaid: boolean
    instaledNoPaid: boolean
}

let valuesFilter: filterSelected = {
    allRecords: true,
    allStock: false,
    stockChips: false,
    stockEquipOnline: false,
    stockChipWithEquip: false,
    allInstaled: false,
    instaledOnline: false,
    instaledOffline: false,
    instaledPaid: false,
    instaledNoPaid: false
}

const pageSize = 300;

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
`;


const optionsActions = [
    //{ value: 'CC', label: 'Contrato à Cancelar' },
    //{ value: 'CA', label: 'Chip Ativo' },
    //{ value: 'CI', label: 'Chip Inativo' },
    //{ value: 'AF', label: 'Alterar Fornecedor / APN' },
    { value: 'RE', label: 'Remover Equipamento' },
    //{ value: 'ADA', label: 'Alterar data Ativação' },
    { value: 'DELCHIP', label: 'Apagar Chips' }
];

const PROVIDERS_CONTENT = 'providers'
const EQUIP_CONTENT = 'equip'
const LOADING_CONTENT = 'loading'
const CONFIRM_REMOVE_EQUIP = 'confirm_rm_equip'
const CONFIRM_ALTER_PROVIDER = 'confirm_alter_provider'
const CONFIRMED_ACTION = 'confirm_action'
const MSG_CONFIRMATION = 'msg_confirme'


let chipToAction: itemChips;
let messageToModal: string;

interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

const ListChipsPage: React.FC = () => {
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const [ResumeChips, setResumeChips] = React.useState<resResumeChips>();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [FilterSelected, setFilterSelected] = React.useState<filterSelected>(valuesFilter);
    const [ShowForm, setShowForm] = React.useState<boolean>(false);



    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<itemChips[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<itemChips[]>(rows);
    const [columnsFilter, setColumnsFilter] = useState<Column<itemChips>[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columnsFilter.map(column => column.key.toString()));

    //modal
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();

    const columns: Column<itemChips>[] = [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'edit',
            name: 'Editar',
            width: 60,
            renderCell(props: any) {
                return (

                    <ButtonEdit onClick={() => handleEditChip(props.row)} />

                )
            }
        },
        {
            key: 'contract_status',
            name: 'Contrato',
            width: 100,
        },
        {
            key: 'status_conn',
            name: 'Status',
            width: 100,
        },
        {
            key: 'equip_status',
            name: 'Equip',
            width: 100
        },
        {
            key: 'fornecedor',
            name: 'Fornecedor',
            width: 150,
        },
        {
            key: 'serial',
            name: 'Serial',
            width: 230,
        },
        {
            key: 'linha',
            name: 'Linha',
            width: 150
        },

        {
            key: 'nDiasConn',
            name: 'Conectado',
            width: 100
        },
        {
            key: 'equip_imei',
            name: 'Imei',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.equip_imei ? <>
                        <div><i>{props.row.equip_imei}</i> / <strong>{props.row.fab_nome}-{props.row.mod_ref}</strong></div>
                    </> : <SmallButton label='Selecionar Equipamento' onClick={() => {
                        chipToAction = props.row
                        handleSelectAction('SE')
                    }
                    } />
                )
            }
        },
        {
            key: 'finance_status',
            name: 'Financeiro',
            width: 100
        },

        {
            key: 'cliente_name',
            name: 'Cliente',
            width: 240
        },
        {
            key: 'veiculo_placa',
            name: 'Veículo',
            width: 130
        },

        /* {
             key: 'equip_imei',
             name: 'Imei',
             width: 130
         },
         {
             key: 'fab_nome',
             name: 'Fabricante',
             width: 130
         },
         {
             key: 'mod_ref',
             name: 'Modelo',
             width: 100
         },*/


    ];





    const handleEditChip = (item: itemChips) => {
        chipToAction = item
        setShowForm(true)
    }
    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    const hideColummsChipStock = () => {
        return FilterSelected.stockChips ?
            true : false
    }
    const showColumnsInUse = () => {
        return FilterSelected.instaledNoPaid ||
            FilterSelected.instaledPaid ||
            FilterSelected.instaledOffline ||
            FilterSelected.allInstaled ||
            FilterSelected.allRecords ||
            FilterSelected.instaledOnline ? false : true
    }





    async function openFormChips() {

    }
    async function reqResumeChips() {
        try {
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                //idEmpresas: [49, 20250087, 20250098]

            }
            const result = await getResumeChips(payload);
            setResumeChips(result)
            reqGetListChips()
        } catch (e) {
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[ListTasksDelivery]', error)
        }
    }
    async function reqGetListChips() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                //idEmpresas: [49, 20250087, 20250098],
                estoqueChips: valuesFilter.stockChips,
                AllStockChips: valuesFilter.allStock,
                onlineChips: valuesFilter.instaledOnline,
                instaladoInadiplentes: valuesFilter.instaledNoPaid,
                instaladoPagos: valuesFilter.instaledPaid,
                estoqueEquipWithChip: valuesFilter.stockChipWithEquip,
                estoqueEquipOnline: valuesFilter.stockEquipOnline,
                totalInstalado: valuesFilter.allInstaled,
                instaladoOffline: valuesFilter.instaledOffline,
                instaladoOnline: valuesFilter.instaledOnline,
            }
            const result = await getListChips(payload);
            if (result) {
                setRows(result)
                setFilteredRows(result);
            }
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }

    const removeEquip = async (reason?: string) => {
        try {
            handleClose()
            setIsLoading(true)
            let payload = {
                ids: Array.from(selectedRows),
                reason: reason ?? 'Não informado'
            }
            await removerEquip(payload);
            reqResumeChips();
            setSelectedRows(new Set())
            setModalContent(CONFIRMED_ACTION)
            setIsModalOpen(true)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }



    useEffect(() => {

        (async () => {
            setColumnsFilter(columns)
            reqResumeChips();
        })();
    }, []);


    const handleRefresh = () => {
        reqResumeChips();
    }
    const handleFormChip = () => {
        setShowForm(true)
    }
    const handleCancelFormChip = () => {
        setShowForm(false)
    }
    const handleSavedChip = () => {
        setShowForm(false)
        reqResumeChips();
    }



    const handleFilter = (item: string) => {
        let filter: filterSelected = {
            allRecords: false,
            allStock: false,
            stockChips: false,
            stockEquipOnline: false,
            stockChipWithEquip: false,
            allInstaled: false,
            instaledOnline: false,
            instaledOffline: false,
            instaledPaid: false,
            instaledNoPaid: false
        }
        filter[item as keyof filterSelected] = true
        valuesFilter = filter
        setFilterSelected(filter)
        reqGetListChips();
        setSelectedRows(new Set())
    }

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    /* const sortedRows = React.useMemo((): readonly itemChips[] => {
         return [...filteredRows].sort((a, b) => {
             for (const sort of sortColumns) {
                 const comparator = getComparator<itemChips>(sort.columnKey);
                 const compResult = comparator(a, b);
                 if (compResult !== 0) {
                     return sort.direction === 'ASC' ? compResult : -compResult;
                 }
             }
             return 0;
         });
     }, [filteredRows, sortColumns]);*/


    const handleSelectAction = (action: string) => {
        switch (action) {
            case 'RE':
                setIsModalOpen(true)
                setModalContent(CONFIRM_REMOVE_EQUIP)
                break
            case 'AF':
                setIsModalOpen(true)
                setModalContent(PROVIDERS_CONTENT)
                break
            case 'SE':
                setIsModalOpen(true)
                setModalContent(EQUIP_CONTENT)
                break
            case 'DELCHIP':
                handleDeleteChip()
                break
        }
    };

    const handleAPNSelected = async (provider: IChipProvider) => {
        try {
            handleClose()
            setIsLoading(true)
            let payload = {
                chip_fornecedor: provider.id_provider,
                chip_apn: provider.id,
                ids: Array.from(selectedRows)
            }
            const result = await alterApn(payload);
            setSelectedRows(new Set())
            console.log(result)

        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };
    const SendEquipelected = async (payload: any) => {
        handleClose()
        try {
            await SetEquipInChip(payload);
            reqResumeChips();
            setModelCmd({
                type: "CONFIRMED",
                requireJustification: false,
                message: `Equipamento vinculado com sucesso!`,
                onConfirm: () => handleCloseModelCmd(),
                title: "Operação Finalizada"
            })
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    };
    const handleEquipelected = async (equip: IEquipList) => {
        handleClose()
        console.log(equip, chipToAction)
        let payload = {
            iddisp: equip.iddisp,
            idchip: chipToAction.id
        }
        setModelCmd({
            type: "QUESTION",
            requireJustification: false,
            message: `Deseja vincular o equipamento com IMEI=${equip.equip_imei} ao chip serial=${chipToAction.serial}?`,
            onConfirm: () => SendEquipelected(payload),
            title: "Vincular Equipamento"
        })
    };


    const handleDeleteChip = async () => {

        setModelCmd({
            type: "QUESTION",
            requireJustification: true,
            message: `Deseja apagar os chips selecionados?`,
            onConfirm: (value) => handleConfirmeDelete(value),
            title: "Apagar Chips"
        })


    }



    const handleConfirmeDelete = async (reason?: string) => {
        try {
            handleClose()
            let payload = {
                ids: Array.from(selectedRows),
                reason: reason
            }
            const result = await deleteChips(payload);
            setSelectedRows(new Set())
            reqResumeChips();
            setModelCmd({
                type: "CONFIRMED",
                requireJustification: false,
                message: `Chips Apagados com Sucesso!`,
                onConfirm: () => handleCloseModelCmd(),
                title: "Operação Finalizada"
            })

        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };

    const handleClickRow = (row: itemChips) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }


    return (
        <>
            {!ShowForm && <ContainerViewTable>
                <ContainerFlexInline>
                    <CardTotal title="Total de Chips" value={ResumeChips?.totalChips ?? 0} isSelected={FilterSelected.allRecords} onClick={(e) => handleFilter('allRecords')} />
                    <CardTotal title="Total Estoque" value={ResumeChips?.totalEstoque ?? 0} isSelected={FilterSelected.allStock} onClick={(e) => handleFilter('allStock')} />
                    <CardTotal title="Estoque Chips" value={ResumeChips?.estoqueChips ?? 0} isSelected={FilterSelected.stockChips} onClick={(e) => handleFilter('stockChips')} />
                    <CardTotal title="Estoque On-Line" value={ResumeChips?.estoqueEquipOnline ?? 0} isSelected={FilterSelected.stockEquipOnline} onClick={(e) => handleFilter('stockEquipOnline')} />
                    <CardTotal title="Estoque c/ Equipamento" value={ResumeChips?.estoqueEquipWithChip ?? 0} isSelected={FilterSelected.stockChipWithEquip} onClick={(e) => handleFilter('stockChipWithEquip')} />
                    <CardTotal title="Total Instalados" value={ResumeChips?.totalInstalado ?? 0} isSelected={FilterSelected.allInstaled} onClick={(e) => handleFilter('allInstaled')} />
                    <CardTotal title="Instalado Online" value={ResumeChips?.instaladoOnline ?? 0} isSelected={FilterSelected.instaledOnline} onClick={(e) => handleFilter('instaledOnline')} />
                    <CardTotal title="Instalado OffLine" value={ResumeChips?.instaladoOffline ?? 0} isSelected={FilterSelected.instaledOffline} onClick={(e) => handleFilter('instaledOffline')} />
                    <CardTotal title="Instalado Inadiplentes" value={ResumeChips?.instaladoInadiplente ?? 0} isSelected={FilterSelected.instaledNoPaid} onClick={(e) => handleFilter('instaledNoPaid')} />
                    <CardTotal title="Instalado Pagos" value={ResumeChips?.instaladoPago ?? 0} isSelected={FilterSelected.instaledPaid} onClick={(e) => handleFilter('instaledPaid')} />
                </ContainerFlexInline>
                <div>
                    {/*<MultiSelect items={ListStatus} onChange={handleSelectedItemsChange} selectedItems={_selectedItems} />*/}
                    {/*<div><SelectComp name="type_unity" options={ListFuel} label="Tipo de Combustível" /></div>*/}
                </div>
                <ContainerTable>
                    <>
                        <ContainerBarTop>
                            <ContainerSearch>

                                <span>Encontrados {filteredRows.length} de {rows.length}</span>
                                <span>Selecionados {selectedRows.size} de {rows.length}</span>
                                <div className="p-4">
                                    {isModalOpen && (
                                        <ModalComp title="" subtitle="" onClose={handleClose}>
                                            {modalContent === PROVIDERS_CONTENT && <ChipProvidersSelect onRowSelect={handleAPNSelected} />}
                                            {modalContent === EQUIP_CONTENT && <ChipEquipSelect onRowSelect={handleEquipelected} />}
                                            {modalContent === CONFIRM_REMOVE_EQUIP && <ConfirmationDialog type='QUESTION' requireJustification={true} message="Justifique a remoção dos equipamentos dos chips selecionados!" onConfirm={removeEquip} onCancel={() => handleClose()} />}
                                            {modalContent === CONFIRM_ALTER_PROVIDER && <ConfirmationDialog type='QUESTION' requireJustification={true} message="Justifique e confirme a alteração da APN / Fornecedor dos chips selecionados!" onConfirm={() => { }} onCancel={() => handleClose()} />}
                                            {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                                            {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}

                                        </ModalComp>
                                    )}
                                </div>
                                <div className="p-4">
                                    {modelCmd && (
                                        <ModalComp title={modelCmd.title ?? ""} subtitle="" onClose={handleCloseModelCmd}>
                                            <ConfirmationDialog
                                                type={modelCmd.type}
                                                requireJustification={modelCmd.requireJustification}
                                                message={modelCmd.message}
                                                onConfirm={modelCmd.onConfirm}
                                                onCancel={() => handleCloseModelCmd()} />
                                        </ModalComp>
                                    )}
                                </div>
                            </ContainerSearch>


                        </ContainerBarTop>
                        <SearchAndSelectContainer>
                            <SearchField onSearch={handleSearch} />
                            <ActionSelect
                                options={optionsActions}
                                onSelect={handleSelectAction}
                                title='Ações'
                            />
                            <ColumnSelect
                                columns={columns}
                                onColumnVisibilityChange={(visibleColumnKeys) => {
                                    setVisibleColumns(visibleColumnKeys);
                                }}
                            />
                            <ButtonRefresh onClick={reqResumeChips} />
                            <ButtonAdd onClick={handleFormChip} />


                        </SearchAndSelectContainer>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            visibleColumns={visibleColumns}
                            onRowClick={handleClickRow}
                            onRowSelect={handleRowSelect}
                        />

                    </>
                </ContainerTable>
            </ContainerViewTable>}
            {ShowForm && <ChipsCadForm onCancelClick={handleCancelFormChip} onSaveClick={handleSavedChip} initialData={chipToAction} />}
        </>
    )

}

export default ListChipsPage

