import React from 'react';
import styled from 'styled-components';
import DataGrid, { CellClickArgs, Column, SortColumn } from 'react-data-grid';
import { getComparator, rowKeyGetter } from '../../utils/datagridTools';
import "../../styles/datagrid/datagrid.css"

const ContainerTable = styled.div`
    padding: 6px;
    height: calc(100vh - 210px);
    overflow: auto;
`;

const ContainerDataGrid = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    block-size: 72vh;
    padding: 8px;
    contain: inline-size;
`;

interface DataGridProps<T> {
    rows: T[];
    columns: Column<T>[];
    selectedRows: ReadonlySet<number>
    visibleColumns?: string[]
    setRows?: React.Dispatch<React.SetStateAction<T[]>>;
    onRowClick?: (row: T) => void;
    onRowSelect?: (selecteds: ReadonlySet<number>) => void;
}

function DataGridComp<T>({ rows, columns, selectedRows, onRowSelect, onRowClick, visibleColumns }: DataGridProps<T>) {

    const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<T[]>(rows);

    React.useEffect(() => {
        setFilteredRows(rows);
    }, [rows]);

    //const visibleColumns = columns.map(column => column.key.toString());
    const filteredColumns = columns.filter(column => visibleColumns ? visibleColumns.includes(column.key.toString()) : columns);


    const sortedRows = React.useMemo((): T[] => {
        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator<T>(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [filteredRows, sortColumns]);

    const handleCellClick = React.useCallback((args: CellClickArgs<T, unknown>) => {
        const row = args.row;
        onRowClick?.(row);
    }, [onRowClick]);

    const handleSelectedRowsChange = React.useCallback((selectedKeys: ReadonlySet<number>) => {
        onRowSelect?.(selectedKeys);
    }, [onRowSelect]);

    return (
        <ContainerTable>
            <ContainerDataGrid>
                <DataGrid
                    columns={visibleColumns ? filteredColumns : columns}
                    rows={sortedRows}
                    rowKeyGetter={rowKeyGetter}
                    //onRowsChange={setRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    onCellClick={handleCellClick}
                    defaultColumnOptions={{
                        resizable: true,
                        sortable: true
                    }}
                    className='DatagridClass'
                />
            </ContainerDataGrid>
        </ContainerTable>
    );
}

export default DataGridComp;
