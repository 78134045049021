import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, FormControlLabel } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import { DateAndTimeFormatterBd, DateFormatter, DateFormatterBd } from '../../utils/format';
import InputMask from 'react-input-mask';
import { ContainerCard, ContainerDate, ContainerHours, ContainerInline, ContainerPicker, Label1 } from './StyleCompGlobal';
import "react-datepicker/dist/react-datepicker.css";
import { ContainerBlock, ContainerFlexInline } from '../../styles/Styles';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import CardVehicleWidget from '../Widgets/CardVehicleWidget';
import CardDriverWidget from '../Widgets/CardDriverWidget';
import { iFilterListSetup, iDrivers, iItemTravel, iVehicle } from '../../interfaces/interfaces';
import MultiSelect from '../Widgets/cardMultipleSelect';
import httpApiV3 from '../../services/http-xsmart-apiv3';
import Cookies from 'js-cookie';
import { ExtractTimeOfDateTime, getDateTimeEnd, getDateTimeStart, getDateToday } from '../../utils/Converter';

const Container = styled('div')({
    background: '#f6f9ff',
    width: '100%',
    height: 500
});

const Subtitle = styled('div')({
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'uppercase',
});


interface DialogProps {
    open: boolean
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
    onSetFilter: (filter: iFilterListSetup) => void
    filterSetup: iFilterListSetup
}

let _dtStart: string = getDateToday()
let _dtEnd: string = getDateToday()
let _hrStart: string = '00:00:00'
let _hrEnd: string = '23:59:59'
let _selectedItems: string[] = []

let filter: iFilterListSetup = {
    isDateFilter: false,
    statusFilter: [],
    SelDriver: null,
    SelVehicle: null
}



const FilterListModal: React.FC<DialogProps> = (props) => {

    const formRef = React.useRef<FormHandles>(null)
    const [isOpenDataPicker, setOpenDataPicker] = React.useState(false);
    const [isUseDateFilter, setUseDateFilter] = React.useState(false);
    const [DriverSel, setDriverSel] = React.useState<iDrivers>();
    const [VehicleSel, setVehicleSel] = React.useState<iVehicle>();
    const [ListStatus, setListStatus] = React.useState<string[]>();

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [HoursStart, setHoursStart] = React.useState<string>(_hrStart);
    const [HoursEnd, setHoursEnd] = React.useState<string>(_hrEnd);

    const [filterInfo, setFilterInfo] = React.useState<string>();



    const initFilter = () => {
        filter = props.filterSetup
        _selectedItems = filter.statusFilter
        setUseDateFilter(filter.isDateFilter)
        if (filter.SelDriver) setDriverSel(filter.SelDriver)
        if (filter.SelVehicle) setVehicleSel(filter.SelVehicle)
        if (filter.isDateFilter) {
            let dti = filter.dtCad?.start.split(' ')
            let dte = filter.dtCad?.end.split(' ')
            _dtStart = dti ? dti[0] : getDateToday()
            _hrStart = dti ? dti[1] : '00:00:00'
            _dtEnd = dte ? dte[0] : getDateToday()
            _hrEnd = dte ? dte[1] : '23:59:59'
            setStartDate(new Date(filter.dtCad?.start ?? new Date()))
            setEndDate(new Date(filter.dtCad?.end ?? new Date()))
            setHoursStart(_hrStart)
            setHoursEnd(_hrEnd)
        }
        updateFilterInfo()
    }

    const updateDateFilter = () => {
        if (filter.isDateFilter) {
            filter.dtCad = { start: DateAndTimeFormatterBd(_dtStart, _hrStart), end: DateAndTimeFormatterBd(_dtEnd, _hrEnd) }
        } else {
            filter.dtCad = null
        }
    }

    const updateFilterInfo = () => {
        let filterDate = filter.isDateFilter ? `${_dtStart} ${_hrStart} à ${_dtEnd} ${_hrEnd}` : 'Qualquer Periodo'
        let filterStatus = _selectedItems.length ? _selectedItems : 'Todos'
        let info = `Data: ${filterDate} / Status: ${filterStatus}`;
        setFilterInfo(info)
    }

    async function getListStatus() {
        try {
            const result = await httpApiV3.get("ListStatusDelivery");
            let resp = result.data;
            if (resp) {
                let list = resp.map((i: any) => {
                    return i.descr
                });
                setListStatus(list)
            }

            initFilter()

        } catch (error) {
            console.log('[ListTasksDelivery]', error)
        }
    }


    const handleChangeIsUseDate = (newValue: boolean) => {
        setUseDateFilter(newValue)
        filter.isDateFilter = newValue
        updateFilterInfo()
    };
    const handleChangeDataStartPicker = (newValue: any) => {
        setOpenDataPicker(false)
        setStartDate(newValue)
        _dtStart = DateFormatterBd(newValue)
        updateFilterInfo()
    };
    const handleChangeDataEndPicker = (newValue: any) => {
        setOpenDataPicker(false)
        setEndDate(newValue)
        _dtEnd = DateFormatterBd(newValue)
        updateFilterInfo()
    };

    const onChangeHoursStart = (event: any) => {
        const { value } = event.target;
        _hrStart = value
        setHoursStart(_hrStart)
        updateFilterInfo()
    }
    const onChangeHoursEnd = (event: any) => {
        const { value } = event.target;
        _hrEnd = value
        setHoursEnd(_hrEnd)
        updateFilterInfo()
    }



    const SubmitsFilter = () => {
        updateDateFilter()
        props.onSetFilter(filter)
    }

    const selectVehicle = (item: iVehicle) => {
        setVehicleSel(item)
        filter.SelVehicle = item
    }
    const clearSelVehicle = () => {
        filter.SelVehicle = null
        setVehicleSel(undefined)

    }
    const selectDriver = (item: iDrivers) => {
        filter.SelDriver = item
        setDriverSel(item)
    }
    const clearSelDriver = () => {
        filter.SelDriver = null
        setDriverSel(undefined)
    }

    const handleSelectedItemsChange = (newSelectedItems: string[]) => {
        _selectedItems = newSelectedItems
        filter.statusFilter = _selectedItems
        updateFilterInfo()
    };

    React.useEffect(() => {
        getListStatus()
    }, [props.open])

    const formDataStart = (
        <React.Fragment>
            <ContainerDate>
                <label >Data Inicial</label>
                <DatePicker
                    selected={startDate}
                    onChange={handleChangeDataStartPicker}
                    popperPlacement="top-end"
                    locale="ptBr"
                    dateFormat="dd-MM-yyyy"
                    className='InputDate'

                />
            </ContainerDate>
        </React.Fragment>
    );
    const formDataEnd = (
        <React.Fragment>
            <ContainerDate>
                <label >Data Final</label>
                <DatePicker
                    selected={endDate}
                    onChange={handleChangeDataEndPicker}
                    popperPlacement="top-end"
                    locale="ptBr"
                    dateFormat="dd-MM-yyyy"
                    className='InputDate'

                />
            </ContainerDate>
        </React.Fragment>
    );

    const formHoursStart = (
        <React.Fragment>
            <ContainerHours>
                <label >Hora Inicial</label>
                <InputMask {...props} mask="99:99:99" maskChar="_" onChange={onChangeHoursStart} value={HoursStart} />
            </ContainerHours>
        </React.Fragment>
    );
    const formHoursEnd = (
        <React.Fragment>
            <ContainerHours>
                <label >Hora Final</label>
                <InputMask {...props} mask="99:99:99" maskChar="_" onChange={onChangeHoursEnd} value={HoursEnd} />
            </ContainerHours>
        </React.Fragment>
    );


    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, minHeight: 500 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Filtro</DialogTitle>
            <DialogContent>


                <Container>
                    <Subtitle>{filterInfo}</Subtitle>
                    <ContainerInline>
                        {<CardVehicleWidget idVehicle={VehicleSel?.id} selectVehicle={selectVehicle} clearSelVehicle={clearSelVehicle} />}
                        {<CardDriverWidget idMotorista={DriverSel?.id} selectDriver={selectDriver} clearSelDriver={clearSelDriver} />}
                    </ContainerInline>
                    <ContainerInline>
                        <ContainerCard>
                            <ContainerInline>
                                <Label1 >Filtrar por período</Label1>
                                <Switch checked={isUseDateFilter} onChange={() => handleChangeIsUseDate(!isUseDateFilter)} name="isFilterByDate" />

                            </ContainerInline>

                            {isUseDateFilter ? <Form placeholder={""} ref={formRef} onSubmit={() => { }} className='Input-Style' noValidate={true} >
                                <ContainerPicker>
                                    {formDataStart}
                                    {formHoursStart}
                                </ContainerPicker>
                                <ContainerPicker>
                                    {formDataEnd}
                                    {formHoursEnd}
                                </ContainerPicker>
                            </Form> : <></>}
                        </ContainerCard>
                        <ContainerCard>
                            {ListStatus && <MultiSelect items={ListStatus} onChange={handleSelectedItemsChange} selectedItems={_selectedItems} />}
                        </ContainerCard>
                    </ContainerInline>
                </Container>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} >
                    Cancelar
                </Button>
                <Button onClick={SubmitsFilter}>
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog></>

}

export default FilterListModal