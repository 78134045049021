import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

interface StatusChipProps {
    status: 'Pendente' | 'Aprovado' | 'Cancelado' | 'Em Análise' | 'Rejeitado' | 'Pago';
}

const getColor = (status: string) => {
    switch (status) {
        case 'Pendente':
            return 'orange';
        case 'Aprovado':
            return 'green';
        case 'Cancelado':
            return 'red';
        case 'Em Análise':
            return 'blue';
        case 'Rejeitado':
            return 'brown';
        case 'Pago':
            return 'purple';
        default:
            return 'grey';
    }
};

const StyledChip = styled(Chip)<{ status: string }>(({ status }) => ({
    backgroundColor: getColor(status) + '50', // 50% opacity
    color: getColor(status),
    border: `1px solid ${getColor(status)}`,
    height: '24px',
}));

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
    return (
        <StyledChip label={status} variant="outlined" status={status} />
    );
};

export default StatusChip;
