import './CardTotal.css'

interface CardProps {
    title: string
    value: number,
    isSelected?: boolean,
    onClick?: (item?: any) => void

}

const CardTotal: React.FC<CardProps> = (props) => {

    const handleOnClick = (item?: any) => {
        if (props.onClick) props.onClick(item)
    }

    return (
        <div className={`Card ${props.isSelected ? 'classCardSelected' : ''}`} onClick={handleOnClick}>
            <div className='title'>{props.title}</div>
            <div className='value'>{props.value}</div>
        </div>
    )
}

export default CardTotal