import React, { useEffect, useState } from 'react';
import { itemSettingsAlerts } from '../../interfaces/types';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Box } from '@mui/material';
import CardOpcoesAlert from './CardOpcoesAlert';
import CardVehiclesAlert from './CardVehiclesAlert';
import CardCercasAlert from './CardCercasAlert';

interface CompProps {
    alert?: itemSettingsAlerts
}


const DetailsAlerts: React.FC<CompProps> = ({ alert }) => {

    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <>
            {alert && <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {alert?.tipo === 'CERCA' && <><TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Opções" value="1" />
                        {/*<Tab label="Horários" value="2" />*/}
                        <Tab label="Veículos" value="3" />
                        <Tab label="Cercas" value="4" />
                        {/*<Tab label="Ajustes" value="5" />*/}
                    </TabList></>}
                    {alert?.tipo !== 'CERCA' && <><TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Opções" value="1" />
                        {/*<Tab label="Horários" value="2" />*/}
                        <Tab label="Veículos" value="3" />
                    </TabList></>}
                </Box>
                <div>
                    <TabPanel value="1"><CardOpcoesAlert alert={alert} /></TabPanel>
                    <TabPanel value="2"><CardOpcoesAlert alert={alert} /></TabPanel>
                    <TabPanel value="3"><CardVehiclesAlert alert={alert} /> </TabPanel>
                    <TabPanel value="4"><CardCercasAlert alert={alert} /> </TabPanel>
                    {/*<TabPanel value="5"><CardOpcoesAlert alert={alert} /> </TabPanel>*/}

                </div>
            </TabContext>}
        </>
    )

}
export default DetailsAlerts