import React from 'react';
import { itemSettingsAlerts } from '../../interfaces/types';
import SelectComp from '../forms/components/SelectFieldComp';
import styled from 'styled-components';

//form
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { ContainerBlock, ContainerFlexInline } from '../../styles/Styles';
import Input from '../forms/components/InputComp';
import { Save } from '@mui/icons-material';


interface CompProps {
    alert: itemSettingsAlerts
}

const LabelItem = styled.div`
    display: block;
    font-size: medium;
    color: #7e7e7e;
    width: 150px;
`
const InfoItem = styled.div`
    display: flex;
    padding-top: 8px;
`
const ValueItem = styled.div`
    display: block;
    margin-left: 10px;
    color: black;
`
const Container = styled('div')({
    display: 'block',
    padding: 8
});





const CardOpcoesAlert: React.FC<CompProps> = ({ alert }) => {



    return (
        <>
            <Container>
                <InfoItem><LabelItem>Código:</LabelItem><ValueItem>{alert.alerta_id}</ValueItem></InfoItem>
                <InfoItem><LabelItem>Descrição:</LabelItem><ValueItem>{alert.descr}</ValueItem></InfoItem>
                <InfoItem><LabelItem>Tipo:</LabelItem><ValueItem>{alert.tipo}</ValueItem></InfoItem>
                <InfoItem><LabelItem>Evento:</LabelItem><ValueItem>{alert.evento}</ValueItem></InfoItem>
                <InfoItem><LabelItem>Num. Alertas:</LabelItem><ValueItem>{alert.nalertas} {alert.nalertas > 1 && <span>( À cada {alert.intervalo} Minutos )</span>}</ValueItem></InfoItem>

            </Container>
        </>
    )

}
export default CardOpcoesAlert