import React, { useContext, useEffect, useRef } from 'react';
import httpApiV3 from '../../services/http-xsmart-apiv3';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import { iPanelDeliveryInfo } from '../Delivery/deliveryInterfaces';
import CardVehicleStream from './Components/CardVehicleStream';
import { iPanelStreamInfo } from './StreamInterfaces';
import styled from 'styled-components';
import WebSocketProvider, { WebSocketContext } from '../../gateways/WebSocket';

let interval: any
let isInit = true
let socket: any;

const ContainerGrid = styled.div`
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 5px;
`

const StreamPage: React.FC = () => {

    const ws = useContext(WebSocketContext);
    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<iPanelStreamInfo[]>([])




    useEffect(() => {
        console.log(ws)
        if (!socket) {
            socket = ws?.socket
            socket.on('862798051106421', (data: any) => {
                console.log(data)
            });
            //componentUnAmount()
            return () => {
                socket.off('862798051106421')
                socket = null
            }
        }
    }, [ws]);

    /* useEffect(() => {
         if (!socket) {
 
 
             socket.on('evt_room', (data: any) => {
                 console.log(data)
             });
 
 
             //componentUnAmount()
             return () => {
                 socket.off('GameStatus')
                 socket = null
             }
 
         }
     }, [rows]);*/


    async function getDados() {
        const result = await httpApiV3.post("/ReadVehicles", {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        });
        let resp: iPanelStreamInfo[] = result.data;
        console.log(resp)
        setRows(resp)
    }

    const CtrlInterval = () => {

        console.log("Start interval Stream")
        interval = setInterval(() => {
            console.log("get Dados Panel")
            getDados()
        }, 5000)

    }

    React.useEffect(() => {
        if (isInit) {
            isInit = false;
            CtrlInterval()
            getDados()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct Stream")
            clearInterval(interval)
            isInit = true;
        }
    }, [])


    return (
        <>

            <ContainerGrid>
                {rows.filter((i: iPanelStreamInfo) => i.tipo === "JC4xx").map((i: iPanelStreamInfo) => <CardVehicleStream dataCard={i}></CardVehicleStream>)}

            </ContainerGrid>
        </>
    )

};

export default StreamPage;
