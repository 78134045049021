import { Camera } from '@mui/icons-material';
import axios from 'axios';
import React from 'react';
import ReactHlsPlayer from 'react-hls-player';
import styled from 'styled-components';

interface HLSPlayerProps {
    keyFile: string;
}

const ContainerNoStream = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: '10px';
    min-height: 192px;   
    width: 100%;
    background-color: #c0c0c0;
`

const HLSPlayer: React.FC<HLSPlayerProps> = ({ keyFile }) => {
    const playerRef = React.useRef<HTMLVideoElement>(null);
    const [srcFile, setSrcFile] = React.useState<string>()
    const [srcIsValid, setSrcIsValid] = React.useState<boolean>(false)

    const validAvatar = () => {

        let streamUrl: string = `${process.env.REACT_APP_LIVE_HLS}/${keyFile}.m3u8`
        console.log(streamUrl)
        axios.head(streamUrl)
            .then(() => {
                setSrcFile(streamUrl);
                setSrcIsValid(true)
            })
            .catch((error) => {
                console.error('A stream notfound:', error);
                setSrcIsValid(false)
            });
    }


    React.useEffect(() => {
        validAvatar()
    }, [keyFile])


    const noStrem = (
        <ContainerNoStream className='cardBox'>
            <Camera></Camera> Sem Sinal
        </ContainerNoStream>
    );

    return (
        <>
            {srcIsValid && srcFile && <ReactHlsPlayer
                src={srcFile}
                autoPlay={true}
                controls={true}
                width="100%"
                height="auto"

                playerRef={playerRef} />
            }
            {!srcIsValid && noStrem}
        </>
    );
};

export default HLSPlayer;
