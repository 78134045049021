import { Dialog, DialogTitle, DialogContent, Stack, DialogActions, Button, List, Avatar, ListItem, ListItemAvatar, ListItemText, Box, TextField, IconButton } from '@mui/material';
import * as React from 'react';

import { ctrlButtons, iDestinos, iFilterListSetup, iMsgAlert, iTasks } from '../../interfaces/interfaces';

import { selectUser } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';

import * as am5percent from "@amcharts/amcharts5/percent";
import httpApiV3 from '../../services/http-xsmart-apiv3';
import FilterListModal from '../../components/Modal/FilterListModal';
import { changeAlert } from '../../redux/alertServiceSlice';
import styled from 'styled-components';
import { selectAppBar } from '../../redux/appBarSlice';
import ActionDeliveryModal from '../../components/Modal/ActionDeliveryModal';
import { FileOpen } from '@mui/icons-material';
import { ContainerBarTop, ContainerDataGrid, ContainerTable, selectCellClassname } from '../../styles/datagrid/datagridStyle';
import { getComparator, renderCheckbox, renderSortStatus, rowKeyGetter } from '../../utils/datagridTools';

import DataGrid, { Column, RenderCheckboxProps, RenderSortStatusProps, SelectColumn, SortColumn, SortDirection } from 'react-data-grid';
import SearchField from '../../components/datagridComp/SearchField';
import ActionSelect from '../../components/datagridComp/ActionSelect';


import ButtonRefresh from '../../components/datagridComp/ButtonRefresh';

let interval: any
let isInit = true

const initialBtns: ctrlButtons = {
    RefreshShow: true,
    FilterShow: true,
    ChatShow: true
}


const Subtitle = styled('div')({
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'uppercase',
});

var rootAm5: any;
var chart: any

let filterList: iFilterListSetup = {

    statusFilter: ['EM PREPARAÇÃO', 'EM TRÂNSITO'],
    isDateFilter: false,
    SelDriver: null,
    SelVehicle: null
}

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; // Espaço entre os componentes
`;

const optionsActions = [
    { value: 'AN', label: 'Adicionar Nota' },
    { value: 'CC', label: 'Categoria à Cancelar' },
    { value: 'CA', label: 'Categoria Ativo' },
    { value: 'CI', label: 'Categoria Inativo' }
];

const ListTasksComp: React.FC = (props) => {


    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);
    const { appbar } = useSelector(selectAppBar);
    //const [listDatagrid, setListDatagrid] = React.useState<iTasks[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);
    const [CtrlBtns, setCtrlBtns] = React.useState<ctrlButtons>(initialBtns);
    const [carFilter, setCarFilter] = React.useState<number[]>([]);
    const [driverFilter, setDriverFilter] = React.useState<number[]>([]);
    const [statusFilter, setStatusFilter] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [IsOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
    const [IsOpenAction, setIsOpenAction] = React.useState<boolean>(false);
    const [filterInfo, setFilterInfo] = React.useState<string>();
    const [itemTask, setItemTask] = React.useState<iTasks>();

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);
    const [rows, setRows] = React.useState<iTasks[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<iTasks[]>(rows);



    const createChart = () => {
        var data = [{
            country: "France",
            sales: 100000
        }, {
            country: "Spain",
            sales: 160000
        }, {
            country: "United Kingdom",
            sales: 80000
        }]


        let series = chart.series.push(
            am5percent.PieSeries.new(rootAm5, {
                name: "Series",
                categorykey: "country",
                valuekey: "sales"
            })
        );

        series.data.setAll(data)
    }


    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }
    const OpenDetails = (item: iTasks) => {
        setItemTask(item)
        setIsOpenAction(true)
    }


    async function getDados() {
        updateFilterInfo()
        try {
            setIsLoading(true)
            const result = await httpApiV3.post("ListTaskDelivey", {
                idclient: user.idcliente,
                dtCad: filterList.isDateFilter ? filterList.dtCad : null,
                status: filterList.statusFilter,
                idDriver: filterList.SelDriver?.id,
                idVehicle: filterList.SelVehicle?.id
            });
            let resp = result.data;
            if (resp) {
                setRows(resp)
                setFilteredRows(resp);
            }
            setIsLoading(false)

        } catch (error) {
            console.log('[ListTasksDelivery]', error)
            setIsLoading(false)
            handleSetMsgInfo({ msg: "error", type: 'error' })
        }
    }


    const columns: Column<iTasks>[] = [
        {
            ...SelectColumn,
            width: 250,
        },
        {
            key: 'actions', name: 'Eventos', width: 70,
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="secondary" aria-label="show Maps" onClick={e => OpenDetails(params.row)}>
                            <FileOpen />
                        </IconButton>

                    </>
                );
            }


        },
        {
            key: 'order_item',
            name: 'Ordem',
            width: 40,
        },
        {
            key: 'status',
            name: 'Status',
            width: 150,
        },
        {
            key: 'DriverName',
            name: 'Colaborador',
            width: 180,
        },
        {
            key: 'veiculo_placa',
            name: 'Veículo',
            width: 150
        },
        {
            key: 'destino',
            name: 'Destino',
            width: 220
        },
        {
            key: 'ender',
            name: 'Endereço',
            width: 350,
        },
        {
            key: 'phone',
            name: 'Telefone',
            width: 130,
        }

    ];

    const CtrlInterval = () => {

        console.log("Start interval")
        interval = setInterval(() => {
            console.log("get Dados Panel")
            getDados()
        }, 5000)

    }

    React.useEffect(() => {
        console.log("Init List Delivery")
        if (isInit) {
            isInit = false;
            CtrlInterval()
            getDados()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct List Delivery")
            clearInterval(interval)
            isInit = true;
        }
    }, [])





    const handleCloseFilter = () => {
        setIsOpenFilter(false)
    }
    const handleCloseDetails = () => {
        setIsOpenAction(false)
    }

    const updateFilterInfo = () => {
        let filterDate = filterList.isDateFilter ? `${filterList.dtCad?.start} à ${filterList.dtCad?.end}` : 'Qualquer Periodo'
        let filterStatus = filterList.statusFilter.length ? filterList.statusFilter : 'Todos'
        let filterVehicle = filterList.SelVehicle ? `${filterList.SelVehicle?.placa}` : 'Todos'
        let filterDriver = filterList.SelDriver ? `${filterList.SelDriver.DriverName}` : 'Todos'
        let info = `Data: ${filterDate} / Status: ${filterStatus} / Veículo ${filterVehicle} / Motorista: ${filterDriver}`;
        setFilterInfo(info)
    }

    const handleSetFilter = (filter: iFilterListSetup) => {
        filterList = filter
        setIsOpenFilter(false)
        getDados()
    }


    const sortedRows = React.useMemo((): readonly iTasks[] => {
        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator<iTasks>(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [filteredRows, sortColumns]);


    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };


    const handleSelectAction = (action: string) => {
        console.log("Ação selecionada:", action);
        //setIsModalOpen(true); // Exemplo: abrir um modal
    };

    return <>
        <Subtitle>{filterInfo}</Subtitle>
        {<ActionDeliveryModal onClose={handleCloseDetails} open={IsOpenAction} task={itemTask} />}
        {<FilterListModal filterSetup={filterList} onClose={handleCloseFilter} open={IsOpenFilter} onSetFilter={handleSetFilter} />}

        <ContainerTable>
            <SearchAndSelectContainer>
                <SearchField onSearch={handleSearch} />
                <ActionSelect
                    options={optionsActions}
                    onSelect={handleSelectAction}
                    title='Selecione a Ação'
                />
                <ButtonRefresh onClick={getDados} />


            </SearchAndSelectContainer>
            <ContainerDataGrid>
                <DataGrid
                    columns={columns}
                    rows={sortedRows}
                    rowKeyGetter={rowKeyGetter}
                    onRowsChange={setRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    renderers={{ renderSortStatus, renderCheckbox }}
                    defaultColumnOptions={{
                        resizable: true,
                        sortable: true
                    }}
                    className='DatagridClass'
                />
            </ContainerDataGrid>
        </ContainerTable>
    </>

}

export default React.memo(ListTasksComp)