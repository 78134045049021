import { CircularProgress, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, Avatar, ListItem, ListItemAvatar, ListItemText, Box, TextField, IconButton } from '@mui/material';
import * as React from 'react';
import axios, { AxiosError } from 'axios';
import httpAxios from '../services/http-common';
import { useDispatch, useSelector } from 'react-redux';
import { changeUser, selectUser } from '../redux/userSlice';
import { changeCred } from '../redux/credSlice';
import CloseIcon from '@mui/icons-material/Close';
import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';
import '../styles/InputForm.css'


import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Input from '../components/forms/components/InputComp';

import '../styles/LoginStyles.css'
import '../styles/InputForm.css'

import { useRef } from 'react';

import * as yup from 'yup';
import LoadingCircularComp from '../components/LoadingCircularComp';
import AlertComp from '../components/AlertComp';
import { iMsgAlert, iUser } from '../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import backimg from '../assets/backgrounds/imgback01.jpg'
import { changeAlert } from '../redux/alertServiceSlice';
import styled from 'styled-components';
import httpApiV3 from '../services/http-xsmart-apiv3';
import httpApiV4 from '../services/http-xsmart-apiv4';
import { converteObjLogin } from '../utils/Converter';


interface FormData {
    login_form: string
    password_form: string
}

interface FormDataRecover {
    login_recover: string
    code_recover: number
    password_recover: string
    password_recoverConfirm: string
}


interface ErrType {
    message: string
}


const ErrText = styled('div')({
    color: 'red',
    padding: 8,
    borderRadius: 4,
    fontSize: 16
});


export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

let timerAlert: any;
let idclient_recover: number = 0
const interval_timeout = 500


const BackLogin = styled('div')({
    display: 'flex',
    padding: 20,
    justifyContent: 'center'
});


interface BackgroundDivProps extends React.HTMLAttributes<HTMLDivElement> {
    imageUrl: string;
}


const BackLoginScreen = styled.div<BackgroundDivProps>`
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-size: cover !important;
  background-position: center;
  background-color: gray;
  background: url(${props => props.imageUrl});
`
const BoxLogo = styled.div`
  width: 100%;
    height: 200px;
    display: flex;
    align-items: center; 
    justify-content: center;
`


const Logomarca = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 100%;
  max-height: 210px;
  z-index: 99999;
`


interface iStylePage {
    backgroundColor: string
}

const initialStyle: iStylePage = {
    backgroundColor: '#f2f2f2'
}

const LoginPage: React.FC = () => {

    const formRef = useRef<FormHandles>(null)


    const [isRegister, setRegister] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [EmailToSend, setEmailToSend] = React.useState<string>('');
    const [isRecover, setRecover] = React.useState<boolean>(false);
    const [ShowAlert, setShowAlert] = React.useState<iMsgAlert>();
    const [StepRecover, setStepRecover] = React.useState<number>(0);
    const [style, setStyle] = React.useState<iStylePage>(initialStyle);
    const [logomarca, setLogomarca] = React.useState<string>();
    const [banner, setBanner] = React.useState<string | null>(null);
    const [favicon, setFavicon] = React.useState<string | null>(null);
    const nav = useNavigate();



    const dispatch = useDispatch();


    const handleGetEmpresa = async () => {

        try {
            let host = window.location.host
            setLoading(true)
            const result = await httpApiV3.get("/configEmpresaByHost", { params: { host: host } });
            setLoading(false)
            let resp = result.data
            document.title = resp.title_page ?? resp.fantasia
            setLogomarca(`${process.env.REACT_APP_LOGOMARCA}/${resp.logomarca ?? 'defaul_logomarca.png'}`)
            setBanner(`${process.env.REACT_APP_BANNER}/${resp.banner ?? 'default_banner.jpg'}`)
            setFavicon(`${process.env.REACT_APP_FAVICON}/${resp.favicon ?? 'favicon.icon'}`)
        } catch (error) {
            setLogomarca(`${process.env.REACT_APP_LOGOMARCA}/default_logomarca.png`)
            setBanner(`${process.env.REACT_APP_BANNER}/default_banner.jpg`)
            setFavicon(`${process.env.REACT_APP_FAVICON}/favicon.ico`)
            setLoading(false)
            const err = error as AxiosError
            handleSetMsgInfo({ msg: err.message, type: 'error' })
        }

    }


    React.useEffect(() => {
        var link: any = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = favicon;
    }, [favicon])

    const handleEntrar = async (data: FormData) => {
        try {
            setLoading(true)
            const result = await httpApiV4.post("/login-web", {
                login: data.login_form,
                password: data.password_form,
                appVersion: process.env.REACT_APP_VERSION_INFO
            });
            let resp = result.data;
            window.localStorage.setItem("dados_token", resp.dados)
            let dados_str = aes.decrypt(resp.dados, process.env.REACT_APP_KEY_AES || '').toString(utf8)
            let dados_user = JSON.parse(dados_str)
            window.localStorage.setItem("token", dados_user.token)
            dispatch(changeUser(converteObjLogin(dados_user)))
            dispatch(changeCred(dados_user.credentials))
            handleSetMsgInfo({ msg: "Login efetuado com sucesso!", type: 'success' })
            if (dados_user.nivel === 1) { nav("/dashboard-max") } else { nav("/map") }


            setTimeout(() => {
                setLoading(false)
            }, interval_timeout);



        } catch (error) {
            console.log(error)
            setLoading(false)
            const err = error as AxiosError
            handleSetMsgInfo({ msg: err.message, type: 'error' })
        }

    }

    const handleRegister = (val: boolean) => {
        formRef.current?.reset()
        formRef.current?.setErrors({})
        setRegister(val)
    }

    const handleRecoverPass = (val: boolean) => {
        formRef.current?.reset()
        formRef.current?.setErrors({})
        handleStepRecover(0)
        setRecover(val)
    }


    const handleStepRecover = (val: number) => {
        formRef.current?.reset()
        formRef.current?.setErrors({})
        setStepRecover(val)
    }

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
        /* if (timerAlert) { clearTimeout(timerAlert) }
         timerAlert = setTimeout(() => {
           setShowAlert({ open: false, type: alert.type })
         }, 5000);*/
    }






    const handleSubmit: SubmitHandler<FormData> = async data => {
        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                login_form: yup.string().required("Este campo não pode ser vazio"),
                password_form: yup.string().required("Este campo não pode ser vazio"),
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            handleEntrar(data)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }



    const handleReqCodeServer = async (params: any) => {
        try {
            setLoading(true)
            const result = await httpAxios.post("/recoverGetCode", params);

            let resp = result.data;
            if (resp.isSuccess) {
                handleSetMsgInfo({ msg: "Código gerado com sucesso", type: 'success' })
                setEmailToSend(resp.dados.email)
                handleStepRecover(1)
            } else {
                handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            }
            setTimeout(() => {
                setLoading(false)
            }, interval_timeout);

        } catch (error) {
            setLoading(false)
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                handleSetMsgInfo({ msg: err.message, type: 'error' })
            }
        }
    }


    const handleVerCodeServer = async (params: any) => {
        try {
            setLoading(true)
            console.log(params)
            const result = await httpAxios.post("/recoverConfirmeCode", params);
            let resp = result.data;
            if (resp.isSuccess) {
                handleSetMsgInfo({ msg: "Código confirmado com sucesso", type: 'success' })
                idclient_recover = resp.iduser
                handleStepRecover(2)
            } else {
                handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            }
            setTimeout(() => {
                setLoading(false)
            }, interval_timeout);

        } catch (error) {
            setLoading(false)
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                handleSetMsgInfo({ msg: err.message, type: 'error' })
            }
        }
    }


    const handleSavePassServer = async (params: any) => {
        try {
            setLoading(true)
            console.log(params)
            const result = await httpAxios.post("/recoverSavePass", params);
            let resp = result.data;
            if (resp.isSuccess) {
                handleSetMsgInfo({ msg: "Senha Alterada com sucesso", type: 'success' })
                handleRecoverPass(false)
            } else {
                handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            }
            setTimeout(() => {
                setLoading(false)
            }, interval_timeout);

        } catch (error) {
            setLoading(false)
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                handleSetMsgInfo({ msg: err.message, type: 'error' })
            }
        }
    }



    const handleSubmitReqRecover: SubmitHandler<FormDataRecover> = async data => {

        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                login_recover: yup.string().required("Esse campo é Obrigatório")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            handleReqCodeServer({ login: data.login_recover })
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }


    const handleSubmitCodeRecover: SubmitHandler<FormDataRecover> = async data => {

        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                code_recover: yup.string().min(6, "o Código contém 6 caracteres").required("Esse campo é Obrigatório"),

            });
            await schema.validate(data, {
                abortEarly: false,
            });
            handleVerCodeServer({ code: data.code_recover })
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }


    const handleSubmitSaveNewPass: SubmitHandler<FormDataRecover> = async data => {

        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                password_recover: yup.string().required("Esse campo é Obrigatório"),
                password_recoverConfirmation: yup.string().required("Esse campo é Obrigatório").oneOf([yup.ref('password_recover')], 'As senhas não conferem'),

            });
            await schema.validate(data, {
                abortEarly: false,
            });
            handleSavePassServer({ password: data.password_recover, iduser: idclient_recover })
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }




    const cardLogin = (
        <React.Fragment>
            <div className='form-container margin-center'>
                <Form placeholder={""} ref={formRef} onSubmit={handleSubmit} className='Input-Style' noValidate={true} >
                    <Input name="login_form" label='Login' placeholder='Login' disabled={isLoading} role='presentation' autoComplete='none' />
                    <Input name="password_form" label='Senha' placeholder='Senha' type='password' disabled={isLoading} role="presentation" autoComplete="off" />
                    {!isLoading ? <button type='submit' className='btn-transparence color-primary'>
                        {isRegister ? 'Salvar' : 'ENTRAR'}
                    </button> : <LoadingCircularComp />}
                </Form>
                <div className='btn-cmd'>
                    <button className='btn-text color-primary' onClick={e => handleRecoverPass(true)}>Esqueceu a Senha?</button>
                </div>
            </div>

        </React.Fragment>
    );


    const cardReqRecover = (
        <React.Fragment>
            <div className='form-container margin-center'>
                <Form placeholder={""} ref={formRef} onSubmit={handleSubmitReqRecover} className='Input-Style' noValidate={true}>
                    <Input name="login_recover" label='Digite seu Login' placeholder='Login de acesso' disabled={isLoading} role="presentation" autoComplete="off" type='text' />
                    {!isLoading ?
                        <div>
                            <button type='submit'>Solicitar</button>
                            <button onClick={(e) => handleClose()}>Cancelar</button>
                        </div>


                        : <LoadingCircularComp />}
                </Form>
            </div>

        </React.Fragment>
    );


    const cardCodeRecover = (
        <React.Fragment>
            <div className='form-container margin-center'>
                <div className='info-email'>Código enviado para o seguinte e-mail {EmailToSend}</div>
                <Form placeholder={""} ref={formRef} onSubmit={handleSubmitCodeRecover} className='Input-Style' noValidate={true}>
                    <Input name="code_recover" label='Código de recuperação' placeholder='Código' type='number' disabled={isLoading} role="presentation" autoComplete="off" />

                    {!isLoading ? <div>
                        <button type='submit'>Verificar</button>
                        <button onClick={(e) => handleClose()}>Cancelar</button>
                    </div> : <LoadingCircularComp />}
                </Form>
            </div>

        </React.Fragment>
    );



    const cardNewPass = (
        <React.Fragment>
            <div className='form-container margin-center'>
                <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveNewPass} className='Input-Style' noValidate={true}>
                    <Input name="password_recover" label='Nova Senha' placeholder='Nova Senha' disabled={isLoading} role="presentation" autoComplete="off" type='password' />
                    <Input name="password_recoverConfirmation" label='Confirme a Senha' placeholder='Confirmar a Senha' type='password' disabled={isLoading} role="presentation" autoComplete="off" />

                    {!isLoading ? <button type='submit' className='color-primary'>
                        Salvar
                    </button> : <LoadingCircularComp />}
                </Form>
            </div>

        </React.Fragment>
    );


    const cardRecover = () => {
        switch (StepRecover) {
            case 0:
                return cardReqRecover
            case 1:
                return cardCodeRecover
            case 2:
                return cardNewPass
            default:
                return cardReqRecover
        }
    }


    const handleClose = () => {
        if (isRecover) {
            handleRecoverPass(false)
        }

    };

    React.useEffect(() => {
        formRef.current?.reset()
        setLoading(false)
        handleRegister(false)
        handleRecoverPass(false)
        handleGetEmpresa();
    }, [])

    return <>
        {/*<div className='backlogin' style={style}>*/}
        <BackLoginScreen imageUrl={banner ?? 'default_banner.jpg'}>

            <div className='dialog-back'>

                <DialogTitle>{isRecover ? 'Recuperar Senha' : ''}
                    {/*<IconButton onClick={handleClose} aria-label="close" sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
            <CloseIcon />
</IconButton>*/}
                </DialogTitle>
                <DialogContent>
                    <BoxLogo><Logomarca src={logomarca ?? 'default_logomarca.jpg'}></Logomarca></BoxLogo>
                    {isRecover ? cardRecover() : cardLogin}
                </DialogContent>
            </div>
            {/*</div >*/}
        </BackLoginScreen>
    </>

}

export default LoginPage