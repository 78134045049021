
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Input from './components/InputComp';
import TextAreaComp from './components/TextFieldComp';
import * as yup from 'yup';

import React, { useRef } from 'react';
import { ctrlButtons, iDrivers, iItemTravel, iMsgAlert, iVehicle } from '../../interfaces/interfaces';
import BarTable from '../BarTable';
import LoadingCircularComp from '../LoadingCircularComp';

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import httpCommon from '../../services/http-common';
import { changeAlert } from '../../redux/alertServiceSlice';
import CardDriverWidget from '../Widgets/CardDriverWidget';
import CardVehicleWidget from '../Widgets/CardVehicleWidget';
import { ContainerFlexInline } from '../../styles/Styles';
import { ConverterNumber } from '../../utils/Converter';

const CtrlBtnsCad: ctrlButtons = {
    CancelShow: true,
    SaveShow: true
}

interface FormDataSave {
    id: number
    idclient: number
    idmotorista: number
    idvehicle: number
    descr: string
    manifest: string
    invoice: string
    commodity: string
    price_commodity: number
    price_unity: number
    weight: number
    rate: number
}



interface CompProps {
    onSaveClick: () => void
    onCancelClick: () => void
    initialData?: iItemTravel
}


const Container = styled('div')({
    display: 'flex',
    padding: 8
});

const ContainerForm = styled('div')({
    display: 'block',
    padding: 8
});


let idMotoristaSel = 0
let idVehicleSel = 0

const TravelForm: React.FC<CompProps> = (props) => {

    const dispatch = useDispatch()
    const formRef = useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    const handleCancelCad = () => {
        formRef.current?.reset()
        props.onCancelClick()
    }


    React.useEffect(() => {
        if (props.initialData) {
            idMotoristaSel = props.initialData.idmotorista
            idVehicleSel = props.initialData.idvehicle
        }
    }, [props.initialData])



    const handleSaveInServer = async (data: FormDataSave) => {
        data.idclient = user.idcliente
        console.log(data)
        const result = data.id ? await httpCommon.post("EndTrip", data) : await httpCommon.post("CreateTrip", data)
        let resp = result.data;
        if (resp.IsSuccess) {
            handleSetMsgInfo({ msg: `Registro ${data.id ? 'Alterado' : 'Criado'} com sucesso!`, type: 'success' })
        } else {
            handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            formRef.current?.setFieldError(resp.field, resp.msg)
        }
        if (resp.IsSuccess) {
            formRef.current?.reset()
            props.onSaveClick()
        }

    }

    const handleSubmitSaveCad: SubmitHandler<FormDataSave> = async data => {
        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                descr: yup.string().required("Esse campo é Obrigatório")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            data.price_commodity = ConverterNumber(data.price_commodity)
            data.price_unity = ConverterNumber(data.price_unity)
            data.weight = ConverterNumber(data.weight)
            data.rate = ConverterNumber(data.rate)
            data.idmotorista = idMotoristaSel
            data.idvehicle = idVehicleSel
            if (props.initialData) { data.id = props.initialData.id }
            handleSaveInServer(data)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }

    const handleSelectDriver = (item: iDrivers) => {
        console.log(item)
        idMotoristaSel = item.id
    }
    const handleSelectVehicle = (item: iVehicle) => {
        idVehicleSel = item.id
    }


    const formItem = (
        <React.Fragment>
            <ContainerForm>
                <Input className='theme-light' name="descr" label='Descrição*' placeholder='Descrição' disabled={isLoading} role="presentation" type='text' />
                <Input className='theme-light' name="manifest" label='Manifest' placeholder='Manifest' disabled={isLoading} role="presentation" type='text' />
                <Input className='theme-light' name="rate" label='Comissão' placeholder='Comissão' disabled={isLoading} role="presentation" type='number' />
                <Input className='theme-light' name="price_unity" label='Preço / Tonelada' placeholder='Preço / Tonelada' disabled={isLoading} role="presentation" type='number' />
                <TextAreaComp rows={5} className='theme-light' name="obs" label='Observação' placeholder='Observação' disabled={isLoading} role="presentation" />
            </ContainerForm>
        </React.Fragment>
    );
    const formMercadoria = (
        <React.Fragment>
            <ContainerForm>
                <Input className='theme-light' name="commodity" label='Mercadoria' placeholder='Mercadoria' disabled={isLoading} role="presentation" type='text' />
                <Input className='theme-light' name="invoice" label='Nota Fiscal' placeholder='Nota Fiscal' disabled={isLoading} role="presentation" type='text' />
                <Input className='theme-light' name="price_commodity" label='Preço Mercadoria' placeholder='Preço Mercadoria' disabled={isLoading} role="presentation" type='phone' />
                <Input className='theme-light' name="weight" label='Qtde Kg/Ton/M3' placeholder='Qtde Kg/Ton/M3' disabled={isLoading} role="presentation" type='email' />
            </ContainerForm>
        </React.Fragment>
    );
    const formLocation = (
        <React.Fragment>
            <ContainerForm>
                <Input className='theme-light' name="start_ender" label='Origem' placeholder='Origem' disabled={isLoading} role="presentation" type='text' />
                <Input className='theme-light' name="end_ender" label='Destino' placeholder='Destino' disabled={isLoading} role="presentation" type='text' />
            </ContainerForm>
        </React.Fragment>
    );

    return (
        <>            <div className='form-box'>
            <Box
                sx={{
                    height: 44,
                    padding: 1,
                    backgroundColor: '#ededed',
                }} >
                <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                    <BarTable CtrlButtons={CtrlBtnsCad} onSaveClick={() => formRef.current?.submitForm()} onCancelClick={handleCancelCad} />

                </Stack>
            </Box>
            <div className='form-container2 margin-left'>
                <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveCad} className='Input-Style' noValidate={true} initialData={props.initialData}>
                    <Container>
                        {formItem}
                        {formMercadoria}
                        {formLocation}
                        {isLoading ? <LoadingCircularComp /> : <></>}
                    </Container>
                    <ContainerFlexInline>
                        {<CardDriverWidget idMotorista={props.initialData?.idmotorista} selectDriver={handleSelectDriver} />}
                        {<CardVehicleWidget idVehicle={props.initialData?.idvehicle} selectVehicle={handleSelectVehicle} />}
                    </ContainerFlexInline>

                </Form>
            </div>
        </div>

        </>
    )

}
export default TravelForm