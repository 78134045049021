
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import VehiclesDialog from '../VehiclesDialog';
import { iVehicle } from '../../interfaces/interfaces';
import httpCommon from '../../services/http-common';



const Box = styled('div')({
    marginLeft: '5px',
    margin: '10px'
});


const Container = styled('div')({
    display: 'flex',
    padding: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    //border: '1px solid #dbdbdb',
    borderRadius: '5px',
    maxWidth: '350px',
    minHeight: '100px',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)'
});

const ContainerInfo = styled('div')({
    display: 'block',
    padding: 5,
    minWidth: '270px'

});

const ContainerInline = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'

});

const TextApelido = styled('div')({
    fontSize: '10px',
    textTransform: 'uppercase'
});
const Title = styled('div')({
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px'
});
const Subtitle = styled('div')({
    fontSize: '10px',
    fontWeight: '600',
    textTransform: 'uppercase',
});


const OnClickPointer = styled('div')({
    cursor: 'pointer'
});

/*
const AvatarStyle = styled.img.attrs({
    src: `${spinImg}`
})`
    margin: 0 auto;
    height: 28vh;
    margin-bottom: 15px;
    margin-top: 15px;
    display: block;
`*/

const Button = styled.button`
    background: black;
    color: white;
    font-size: 1em;
    margin: 0 auto;
    padding: 0.45em 2em;
    border: 0;
    border-radius: 3px;
    display: block;
    margin-top: 25px;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: var(--btn-hover-color) !important;
    }
`

interface iPropsVehicle {
    idVehicle?: number
    selectVehicle?: (item: iVehicle) => void
    clearSelVehicle?: () => void
}

const CardVehicleWidget: React.FC<iPropsVehicle> = (props) => {

    const [Vehicle, setVehicle] = React.useState<iVehicle>()
    const [IsOpen, setOpen] = React.useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClearSelect = () => {
        setVehicle(undefined)
        if (props.clearSelVehicle) props.clearSelVehicle()
    };

    const handleSelect = (item: iVehicle) => {
        handleClose()
        setVehicle(item)
        if (props.selectVehicle) { props.selectVehicle(item) }
    };



    const handleGetInfoVehicle = async () => {
        const result = await httpCommon.get("getVehicleInfo", { params: { id: props.idVehicle } })
        let resp = result.data;
        console.log(resp)
        handleSelect(resp)
    }


    React.useEffect(() => {
        if (props.idVehicle) {
            handleGetInfoVehicle()
        }
    }, [props.idVehicle])


    return (
        <>
            {<VehiclesDialog onClose={handleClose} open={IsOpen} onMultiSelect={false} handleSelect={handleSelect} />}
            <Box>
                <TextApelido>Veículo</TextApelido>
                {Vehicle?.id ?
                    <><Container onClick={() => handleClickOpen()}>
                        <Avatar src="/broken-image.jpg" />
                        <ContainerInfo>
                            <TextApelido>{Vehicle?.marca} - {Vehicle?.modelo}</TextApelido>
                            <Title>{Vehicle?.placa}</Title>
                            <ContainerInline>
                                <Subtitle>{Vehicle?.km_total ?? 0} KM</Subtitle>
                                <Subtitle>ANO: {Vehicle?.ano}</Subtitle>
                            </ContainerInline>
                        </ContainerInfo>
                    </Container>
                        <ContainerInfo>
                            <Subtitle><OnClickPointer onClick={handleClearSelect}>❌ Remover Seleção</OnClickPointer></Subtitle>
                        </ContainerInfo>
                    </>
                    :
                    <Container onClick={() => handleClickOpen()}>
                        <Avatar src="/broken-image.jpg" />
                        <ContainerInfo>
                            <Subtitle>Selecione um veículo</Subtitle>
                        </ContainerInfo>
                    </Container>
                }
            </Box>
        </>
    )

}
export default CardVehicleWidget