import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styled-components';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import SelectFormInput from '../../../components/datagridComp/SelectFormInput';
import { iDrivers, iVehicle } from '../../../interfaces/interfaces';
import CardVehicleWidget from '../../../components/Widgets/CardVehicleWidget';
import CardDriverWidget from '../../../components/Widgets/CardDriverWidget';


export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;



export type typeMethods = "ALTERSTATUS" | "ALTERVEHICLE" | "ALTERDRIVER";


interface SidebarEditProps {
    message?: string;
    type: typeMethods;
    onCancel?: () => void;
    onConfirm?: (value?: any) => void;
    requireJustification?: boolean;
}

interface IOption {
    value: string;
    label: string;
}

const optionsStatus: IOption[] = [
    { value: 'PENDENTE', label: 'Pendente' },
    { value: 'APROVADO', label: 'Aprovado' },
    { value: 'REJEITADO', label: 'Rejeitado' },
    { value: 'CANCELADO', label: 'Cancelado' },

];

const SidebarEdit: React.FC<SidebarEditProps> = ({ type, message, onCancel, onConfirm, requireJustification }) => {
    const [justification, setJustification] = React.useState('');
    const [selectValue, setSelectValue] = React.useState<string>('');
    const [selectVehicle, setSelectVehicle] = React.useState<iVehicle>();
    const [selectDriver, setSelectDriver] = React.useState<iDrivers>();
    const [errorJustification, setErrorJustification] = React.useState('');


    const handleConfirm = () => {
        if (requireJustification) {
            if (justification == null) {
                setErrorJustification("Você precisa informar o motivo!")
                return
            }
            if (justification.trim().length < 10) {
                setErrorJustification("Digite no minímo 10 caracteres")
                return
            }
        }
        if (type === "ALTERSTATUS" && selectValue === "") {
            setErrorJustification("Você precisa selecionar um status!")
            return
        }
        if (type === "ALTERVEHICLE" && selectVehicle === undefined) {
            setErrorJustification("Você precisa selecionar um veículo!")
            return
        }
        if (type === "ALTERDRIVER" && selectDriver === undefined) {
            setErrorJustification("Você precisa selecionar um motorista!")
            return
        }

        switch (type) {
            case "ALTERSTATUS":
                onConfirm?.({ justification, selectValue });
                break;
            case "ALTERVEHICLE":
                onConfirm?.({ justification, selectVehicle });
                break;
            case "ALTERDRIVER":
                onConfirm?.({ justification, selectDriver });
                break;
        }
    };

    const handleCancel = () => {
        ClearFields()
        onCancel?.();
    }

    const ClearFields = () => {
        setErrorJustification("")
        setJustification("")
        setSelectValue("")
    }


    React.useEffect(() => {
        ClearFields()
    }, [])

    return (
        <div className="flex flex-col items-center justify-center p-4 space-y-6 max-w-96">
            <p className="text-center text-lg font-semibold mb-6 mx-4">
                {message}
            </p>
            {type === "ALTERSTATUS" && (
                <SelectFormInput
                    options={optionsStatus}
                    onSelect={(value) => setSelectValue(value)}
                    selected={selectValue}
                    title='Selecione o status'
                />
            )}
            {type === "ALTERVEHICLE" && (
                <CardVehicleWidget idVehicle={0} selectVehicle={setSelectVehicle} />
            )}
            {type === "ALTERDRIVER" && (
                <CardDriverWidget idMotorista={0} selectDriver={setSelectDriver} />
            )}
            {requireJustification && (
                <>
                    <textarea
                        className="w-full max-w-md p-2 border-2 border-gray-300 rounded-lg"
                        rows={3}
                        placeholder="Justificativa"
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                    ></textarea>
                    {errorJustification && (
                        <ErrorMessage>
                            <FontAwesomeIcon icon={faExclamationCircle} /> {errorJustification}
                        </ErrorMessage>

                    )}
                </>
            )}
            {<div className="flex justify-around w-full max-w-md">
                <button
                    className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                    onClick={handleCancel}>
                    Cancelar
                </button>
                <button
                    className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                    onClick={handleConfirm}>
                    Confirmar
                </button>
            </div>}

        </div>
    );
};


export default SidebarEdit;
