// src/context/NotificationContext.tsx

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type Notification = {
  id: number;
  title: string;
  message: string;
  type: 'error' | 'info' | 'success' | 'warning';
  iconSvg?: string;
  sound?: string;
};

type NotificationContextType = {
  notifications: Notification[];
  addNotification: (
    title: string,
    message: string,
    type: 'error' | 'info' | 'success' | 'warning',
    iconSvg?: string,
    sound?: string
  ) => void;
  removeNotification: (id: number) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);




export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (
    title: string,
    message: string,
    type: 'error' | 'info' | 'success' | 'warning', // Alteração da posição padrão
    iconSvg?: string,
    sound?: string
  ) => {
    const id = new Date().getTime();
    setNotifications(prev => [...prev, { id, title, message, type, iconSvg, sound }]);
    if (sound) {
      const audio = new Audio(`/assets/sounds/${sound}`);
      audio.play().catch(e => console.error("Error playing sound:", e));
    }
    setTimeout(() => {
      removeNotification(id);
    }, 3000); // Notificação desaparece após 3 segundos
  };

  const removeNotification = (id: number) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
