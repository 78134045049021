import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import IconCancel from '../../../assets/icons/VectorCancel.png'
import IconDelivery from '../../../assets/icons/VectorDelivered.png'
import IconSpeed from '../../../assets/icons/VectorSpeed.png'
import IconChecked from '../../../assets/icons/Vectorchecked.png'
import { iPanelStreamInfo } from '../StreamInterfaces';
import HLSPlayer from '../widgets/PlayerHls';
import WidgetsIconVehicle from '../../../widgets/WidgetsIconVehicle';
import WidgetsAvatarDriver from '../../../widgets/WidgetsAvatarDriver';
import { Camera, CameraFront, CameraRear } from '@mui/icons-material';
import { iPrepareCmd, iValueCmd } from '../../../interfaces/types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import httpApiV3 from '../../../services/http-xsmart-apiv3';

//ASSETS

const Row = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    `

const Column = styled.div`
width: 100%;
    display: block;`

const Divider = styled.div`
    width: 100%;
    border: 0.5px solid #cfcfcf7d;
    `

const ImgAvatar = styled.img.attrs(props => ({
    src: `${props.src}`
}))
    `
width: 60px;
height: 60px;
border-radius: 50%;
border: 1px solid red;
display: flex;`


const ImgIcon = styled.img.attrs(props => ({
    src: `${props.src}`
}))
    `
padding-right: 5px;    
width: 22px;`



const FontBold16px = styled.div`
    font-size:16px;
    font-weight: 500;
    `
const Font12px = styled.div`
    font-size:12px;
    `
const ValueIcon = styled.div`
    font-size:14px;
    padding-right: 5px;
    `


const ContainerCard = styled.div`
      display: flex;
    flex-direction: column;
    padding: 8px;
    background: #ffffff;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
    width: 350px;
    min-height: 310px;
`

const Button = styled.button`
    background: black;
    color: white;
    font-size: 1em;
    margin: 0 auto;
    padding: 0.45em 2em;
    border: 0;
    border-radius: 3px;
    display: block;
    margin-top: 25px;
    cursor: pointer;
    &:hover,
    &:focus {
        background-color: var(--btn-hover-color) !important;
    }
`
export const ContainerFlexCenter = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px'
});

export const ContainerButton = styled.button`
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;

`

interface PropsComp {
    dataCard: iPanelStreamInfo
}

function GetUrlAvatar(fileName: string) {
    const img = '' //await import(`../../../assets/icons_maps/${fileName}`)
    return img
}

const CardVehicleStream: React.FC<PropsComp> = (props) => {

    const { user } = useSelector(selectUser);


    const sendOpenStream = async (type: string) => {
        console.log(type, props.dataCard.imei)
        let valuesArr: iValueCmd[] = []
        valuesArr.push({
            key: '${cam}',
            value: type
        })

        let payload: iPrepareCmd = {
            imei: props.dataCard.imei,
            userInfo: user.iduser.toString(),
            sintaxe_cmd: 'RTMP,ON,${cam}',
            model_equip: 'JC4xx',
            type_cmd: 'RTMP',
            categ: 'RTMP',
            values: valuesArr
        }

        const result = await httpApiV3.post("/sendCommand", payload);
        let resp: any = result.data;
        console.log(resp)
    }

    return (
        <>
            <ContainerCard >
                <Row>
                    {props.dataCard.DriverAvatar ?
                        <WidgetsAvatarDriver fileName={props.dataCard.DriverAvatar}></WidgetsAvatarDriver>
                        : <WidgetsIconVehicle fileName={props.dataCard.icone}></WidgetsIconVehicle>}
                    <Column>
                        <FontBold16px>{props.dataCard.vehicle_placa}</FontBold16px>
                        <Font12px>{props.dataCard.manufacturer_vehicle}-{props.dataCard.model_vehicle}</Font12px>

                        <Divider></Divider>
                        <Row>
                            <FontBold16px>{props.dataCard.DriverName}</FontBold16px>
                        </Row>
                    </Column>
                </Row>
                <Divider></Divider>
                <Row>
                    <Font12px>{props.dataCard.txdtEquip}</Font12px>
                    <Font12px>{props.dataCard.igndt}</Font12px>
                    <ImgIcon src={IconSpeed}></ImgIcon>
                    <ValueIcon>{props.dataCard.gpsvel}</ValueIcon>
                </Row>
                <HLSPlayer keyFile={props.dataCard.imei} />
                <ContainerFlexCenter>
                    {/*<ContainerButton className='color-primary'> <EditSharp /> Editar Cadastro</ContainerButton>*/}
                    <ContainerButton className='color-primary' onClick={() => sendOpenStream('IN')}> <CameraFront />Interna</ContainerButton>
                    <ContainerButton className='color-primary' onClick={() => sendOpenStream('OUT')}> <CameraRear />Externa</ContainerButton>

                </ContainerFlexCenter>
            </ContainerCard>
        </>
    )

}
export default CardVehicleStream 