import React from 'react';
import ConfirmationDialog, { typeMethods } from '../../components/datagridComp/ConfirmationDialog';
import { Column, SelectColumn } from 'react-data-grid';
import ModalComp from '../../components/datagridComp/ModalComp';
import LoadingIndicator from '../../components/datagridComp/LoadingComp';
import DataGridComp from '../../components/datagridComp/DataGridComp';
import ButtonEdit from '../../components/datagridComp/ButtonEdit';
import { PriorityItem, BtnCheckBox, ContainerBarTop, ContainerInfo, ContainerSearch, ContainerSpan, ContainerTable, GridContainer, InfoBox, SearchAndSelectContainer, PointsItem } from './style/StyleExpenses';
import SearchField from '../../components/datagridComp/SearchField';
import ActionSelect from '../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../components/datagridComp/ColumnSelect';
import ButtonRefresh from '../../components/datagridComp/ButtonRefresh';
import ButtonAdd from '../../components/datagridComp/ButtonAdd';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import { faTable, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iReduxMap } from '../../interfaces/interfaces';
import { ActionMaps } from '../../utils/values';
import { changeMap } from '../../redux/mapSlice';
import { getExpenses, getExpensesByClient, ExpenseItemScreen, reqExpenses, alterStatus, alterVehicles, alterDrivers } from './repository/ExpensesRepo';
import ExpensesCard from './components/ExpenseCard';
import StatusChip from './components/StyledChip';
import Sidebar from '../../components/datagridComp/SideBar';
import SidebarEdit from './components/SidebarEdit';
import { useNotifications } from '../../Notifications/NotificationContext';


interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

interface iAlterStatus {
    ids: number[],
    newStatus: string,
    reason: string
}
interface iAlterObject {
    ids: number[],
    idParam: number,
    reason: string
}

const LOADING_CONTENT = 'loading'
const CONFIRMED_ACTION = 'confirm_action'
const JUSTIFIED_ACTION = 'justified_action'
const DETAILS_EXPENSES = 'DETAILS_EXPENSES'
const FILTER_EXPENSES = 'FILTER_EXPENSES'
const EDIT_EXPENSES = 'EDIT_EXPENSES'
const ALTERSTATUS = 'ALTERSTATUS'
const ALTERVEHICLE = 'ALTERVEHICLE'
const ALTERSUPPLIERS = 'ALTERSUPPLIERS'
const ALTERDRIVER = 'ALTERDRIVER'

const optionsActions = [
    { value: 'ALTERSTATUS', label: 'Alterar Status' },
    { value: 'ALTERVEHICLE', label: 'Alterar Veículo' },
    { value: 'ALTERDRIVER', label: 'Alterar Motorista' },

];

const ExpensesPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);
    const [isTableView, setViewTable] = React.useState(() => {
        const saved = localStorage.getItem('viewTableExpenses');
        return saved === null ? true : saved === 'true';
    });

    //modal
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();

    //sidebar
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const handleCloseSideBar = () => setIsSidebarOpen(false);

    const [expenseSel, setExpenseSel] = React.useState<ExpenseItemScreen>();
    const [sideContent, setSideContent] = React.useState<string>();

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<ExpenseItemScreen[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<ExpenseItemScreen[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<ExpenseItemScreen>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));

    React.useEffect(() => {
        getDados()
    }, [])
    React.useEffect(() => {
        localStorage.setItem('viewTableExpenses', isTableView.toString());
    }, [isTableView]);




    const columns: Column<ExpenseItemScreen>[] = [
        {
            ...SelectColumn,
            name: 'Selecionar',
            width: 150,
        },
        {
            key: 'status_info',
            name: 'Lida',
            width: 35,
            renderCell(props: any) {
                return (
                    props.row.status_info === 0 ? <div title="Nova Notificação">✉️</div> : <div title="Notificação Lida">📝</div>

                )
            },
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },

        {
            key: 'edit',
            name: 'Ações',
            width: 80,
            renderCell(props: any) {
                return (
                    <BtnCheckBox onClick={() => viewInMap(props.row)}>📂 Abrir</BtnCheckBox>
                )
            }
        },
        {
            key: 'status',
            name: 'Status',
            width: 120,
            renderCell: (props: any) => (
                <StatusChip status={props.row.status} />
            )
        },
        {
            key: 'categories',
            name: 'Categoria',
            width: 150,
        },
        {
            key: 'amount',
            name: 'Valor',
            width: 80,
            renderCell(props: any) {
                return (
                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.row.amount as number
                    ))
            }
        },
        {
            key: 'placa',
            name: 'Veículo',
            width: 150,
        },
        {
            key: 'drivername',
            name: 'Motorista',
            width: 150,
        },

        {
            key: 'evt_at',
            name: 'Data',
            width: 180,
            renderCell(props: any) {
                return new Date(props.row.evt_at).toLocaleString()
            },
        },
        {
            key: 'supplier_name',
            name: 'Fornecedor',
            width: 180
        },
        {
            key: 'descr',
            name: 'Descrição',
            width: 350,
            renderCell(props: any) {
                return props.row.descr.replace(/<[^>]*>?/gm, '');
            }
        },


    ];

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleEdit = (row: ExpenseItemScreen) => {
        // console.log(row)
    }
    const handleClickRow = (row: ExpenseItemScreen) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const alterStatusExpenses = async (value: any) => {
        handleCloseSideBar()
        try {
            const payload: iAlterStatus = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                newStatus: value.selectValue,
            }
            const result = await alterStatus(payload);
            deselectAll()
            setIsModalOpen(true)
            setModalContent(CONFIRMED_ACTION)
            getDados()
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }
    const alterVehiclesExpenses = async (value: any) => {
        handleCloseSideBar()
        try {
            const payload: iAlterObject = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                idParam: value.selectVehicle.id,
            }
            const result = await alterVehicles(payload);
            deselectAll()
            setIsModalOpen(true)
            setModalContent(CONFIRMED_ACTION)
            getDados()
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }
    const alterDriverExpenses = async (value: any) => {
        handleCloseSideBar()
        try {
            const payload: iAlterObject = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                idParam: value.selectDriver.id,
            }
            const result = await alterDrivers(payload);
            deselectAll()
            setIsModalOpen(true)
            setModalContent(CONFIRMED_ACTION)
            getDados()
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos uma linha', 'warning', `https://sistema.maxtracer.com.br/assets/user_phone.svg`);
            return;
        }
        switch (action) {
            case ALTERSTATUS:
                setIsSidebarOpen(true)
                setSideContent(ALTERSTATUS)
                break
            case ALTERVEHICLE:
                setIsSidebarOpen(true)
                setSideContent(ALTERVEHICLE)
                break
            case ALTERSUPPLIERS:
                setIsSidebarOpen(true)
                setSideContent(ALTERSUPPLIERS)
                break
            case ALTERDRIVER:
                setIsSidebarOpen(true)
                setSideContent(ALTERDRIVER)
                break
        }
    };

    async function getDados() {
        try {

            const result = await getExpensesByClient(user.idcliente);
            console.log(result)
            setRows(result)
            setFilteredRows(result);
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    const handleSelectByCard = (id: number) => {
        setSelectedRows((prevSelectedRows) => {
            const newSet = new Set(prevSelectedRows);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const viewInMap = (Expense: ExpenseItemScreen) => {

        setIsSidebarOpen(true)
        setSideContent(DETAILS_EXPENSES)
        setExpenseSel(Expense)
    }

    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
    };

    return (
        <>
            <ContainerTable>
                <>
                    <SearchAndSelectContainer>
                        <SearchField onSearch={handleSearch} />
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />
                        <ColumnSelect
                            columns={columns}
                            onColumnVisibilityChange={(visibleColumnKeys) => {
                                setVisibleColumns(visibleColumnKeys);
                            }}
                        />
                        <button onClick={() => setIsSidebarOpen(true)} className="border border-gray-300 px-4 py-1 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500">
                            🗓 Filtros Avançados
                        </button>
                        <ButtonRefresh onClick={getDados} />


                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={() => setViewTable(true)}>
                            <FontAwesomeIcon icon={faTable} /> Tabela
                        </BtnCheckBox>
                        <BtnCheckBox onClick={() => setViewTable(false)}>
                            <FontAwesomeIcon icon={faAddressCard} /> Card
                        </BtnCheckBox>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    {isTableView ? <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        visibleColumns={visibleColumns}
                        onRowClick={handleClickRow}
                        onRowSelect={handleRowSelect}
                    /> :
                        <GridContainer>
                            {filteredRows.map((Expense) => (
                                <ExpensesCard
                                    key={Expense.id}
                                    Expense={Expense}
                                    onSelect={handleSelectByCard}
                                    isSelected={selectedRows.has(Expense.id)}
                                />
                            ))}
                        </GridContainer>}

                </>
            </ContainerTable>
            {isModalOpen && (
                <ModalComp title="" subtitle="" onClose={handleClose}>
                    {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                    {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}

                </ModalComp>
            )}
            <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} >
                {sideContent === DETAILS_EXPENSES && expenseSel && <ExpensesCard
                    key={expenseSel.id}
                    Expense={expenseSel}
                    onSelect={handleSelectByCard}
                    isSelected={false}
                />}
                {sideContent === ALTERSTATUS && <SidebarEdit
                    requireJustification={true}
                    type={sideContent}
                    message="Alterar Status das Despesas selecionadas!"
                    onConfirm={alterStatusExpenses}
                    onCancel={() => handleCloseSideBar()}
                />}
                {sideContent === ALTERVEHICLE && <SidebarEdit
                    requireJustification={true}
                    type={sideContent}
                    message="Alterar Veículo das Despesas selecionadas!"
                    onConfirm={alterVehiclesExpenses}
                    onCancel={() => handleCloseSideBar()}
                />}
                {sideContent === ALTERDRIVER && <SidebarEdit
                    requireJustification={true}
                    type={sideContent}
                    message="Alterar Motorista das Despesas selecionadas!"
                    onConfirm={alterDriverExpenses}
                    onCancel={() => handleCloseSideBar()}
                />}
            </Sidebar>
        </>
    )

}
export default ExpensesPage