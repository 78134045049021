import { Box, Stack } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ctrlButtons, iMarker, iPosition, iReduxMap } from '../../interfaces/interfaces';
import { changeMenu, selectAppBar } from '../../redux/appBarSlice';
import { changeMap, selectMap } from '../../redux/mapSlice';
import '../../styles/Map.css';
import BarTable from '../BarTable';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ActionMaps } from '../../utils/values';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/DimensionHook';
import { DEFAULT_HEIGHT_SUB_GOOGLEMAPS, DEFAULT_WIDTH_SUB_GOOGLEMAPS } from '../../utils/config';


const ToolbarContainer = styled.div`
  height: 44px;
  background-color: #343541;
  padding: 1px;
`;

interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
    setDistanceInKm?: React.Dispatch<React.SetStateAction<number>>;
}

interface IMarker {
    address: string;
    latitude: number;
    longitude: number;
}

interface iRespGeocoder {
    address: string | null
    position: google.maps.LatLng | iPosition
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;
type GooglePolyline = google.maps.Polyline;

const CtrlBtnsInit: ctrlButtons = {
    CancelShow: true,
    SaveShow: true,
    SearchBarShow: true
}



const zoomInit = 4
const zoomClickMap = 13
const centerBrazil = { lat: -13.293067, lng: -57.680107 };
let WaitLoaderMap = false
var vMarker: google.maps.Marker | null;
var vListMarker: google.maps.Marker[];
var vPolyLine: google.maps.Polyline | null
var markerCluster: MarkerClusterer
var zoomChanged = zoomClickMap
var listenerMapClick: any;

interface iele {
    size: number
}

/*
const MapObject = styled.div.attrs(props => ({
    height: `${props.hight}`
}))*/

const MapObject = styled.div
    `
    display: flex;
    justify-content: center;
    height: 85vh;
    width: 100%;
    background: white;`



const GoogleMapsV2: React.FC = () => {

    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const { mapOptions } = useSelector(selectMap);
    const { appbar } = useSelector(selectAppBar);
    const [map, setMap] = useState<GoogleMap>();
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [ListMarkers, setListMarkers] = useState<GoogleMarker[]>([]);
    const [CtrlBtns, setCtrlBtns] = useState<ctrlButtons>(CtrlBtnsInit);

    const [msgBottom, setMsgBotton] = useState<string | null>('');

    const [StyleMaps, setStyleMaps] = useState({});
    const [StylePanel, setStylePanel] = useState({});

    const { height, width } = useWindowDimensions();






    const startMap = (): void => {
        if (!map) {
            initMap();
        }

    };
    useEffect(startMap, [map]);

    function DimensionMap() {
        let customWidth = mapOptions.width ? mapOptions.width : DEFAULT_WIDTH_SUB_GOOGLEMAPS
        let customHeight = mapOptions.height ? mapOptions.height : DEFAULT_HEIGHT_SUB_GOOGLEMAPS
        console.log(customHeight, customWidth)
        if (customWidth && customHeight) {
            let widthObj = width - customWidth
            let heightObj = height - customHeight
            setStyleMaps({
                height: heightObj + "px",
                width: widthObj + "px"
            })
            setStylePanel({
                width: widthObj + "px",
            })
        }
    }

    useEffect(() => {
        DimensionMap()
    }, [height, width]);

    useEffect(() => {
        console.log("open map", appbar.openMap)
        if (appbar.openMap) {
            DimensionMap();
        }
    }, [appbar.openMap])


    const ActiveClickMap = () => {
        if (map) {
            if (listenerMapClick) google.maps.event.removeListener(listenerMapClick);
            listenerMapClick = google.maps.event.addListener(map, 'click', function (e: any) {
                coordinateToAddress(e.latLng);
            })
        }
    }

    const resetMap = () => {
        map?.panTo(centerBrazil)
        map?.setZoom(zoomInit)
        zoomChanged = zoomClickMap
        if (vMarker) {
            vMarker.setMap(null)
            vMarker = null
        }
        if (vPolyLine) {
            vPolyLine.setMap(null)
            vPolyLine = null
        }
        if (markerCluster) {
            markerCluster.clearMarkers()
        }
        vListMarker && vListMarker.forEach(mkr => {
            mkr.setMap(null)
        })
        vListMarker = []
    }

    const UpdateMapLocal = (): void => {
        console.log("UpdateMapLocal", mapOptions)
        //if (map) google.maps.event.clearInstanceListeners(map);
        let action = mapOptions?.action ?? false
        let InsertNewMultiplesMarkers = mapOptions?.markers?.length
        let InsertNewOneMarker = action === ActionMaps.SHOW_MARKER && mapOptions?.marker
        let isSHOWROUTER = action === ActionMaps.SHOW_ROUTER
        let isCREATEMARKER = action === ActionMaps.CREATE_MARKER
        let isSHOWMARKER = action === ActionMaps.SHOW_MARKER
        let isSHOWMARKERS = action === ActionMaps.SHOW_MARKERS

        if (isSHOWROUTER) {
            resetMap()
            if (listenerMapClick) google.maps.event.removeListener(listenerMapClick);
            mapOptions.markers && addListMarker(mapOptions.markers)
            addPolyline(mapOptions.polyline)
            return
        }
        if (isCREATEMARKER) {
            resetMap()
            ActiveClickMap()
            mapOptions?.marker && addOneMarkerinMap(mapOptions?.marker)
            if (mapOptions?.ender) {
                setMsgBotton(mapOptions.ender)
                if (!mapOptions?.marker) {
                    AddressToCoordinate(mapOptions.ender)
                }
            }
            return
        }
        if (isSHOWMARKER) {
            resetMap()
            mapOptions?.marker && addOneMarkerinMap(mapOptions?.marker)
            return
        }
        if (isSHOWMARKER) {
            resetMap()
            mapOptions?.marker && addOneMarkerinMap(mapOptions?.marker)
            return
        }
        if (isSHOWMARKERS) {
            if (!mapOptions.noFitBounds) {
                console.log("NoResetMap")
                resetMap()
            }
            mapOptions.markers && addListMarker(mapOptions.markers)
            return
        }

        /* if (mapOptions.resetMap) {
             console.log("ACTION:RESET MAP")
             resetMap()
         }
         if (InsertNewOneMarker) {
             console.log("RENDER:MARKER", mapOptions.marker)
             mapOptions?.marker && addOneMarkerinMap(mapOptions?.marker)
         }
         if (mapOptions?.ender) {
             console.log("RENDER:ENDER", mapOptions.ender)
             setMsgBotton(mapOptions.ender)
     
             if (!mapOptions?.marker) {
                 AddressToCoordinate(mapOptions.ender)
             }
         }
         if (InsertNewMultiplesMarkers) {
             console.log("RENDER:LISTMARKER", mapOptions.markers)
             mapOptions.markers && addListMarker(mapOptions.markers)
         }
     
         if (mapOptions.polyline?.length) {
             addPolyline(mapOptions.polyline)
         }*/

    };


    useEffect(UpdateMapLocal, [mapOptions]);



    const GeocoderService = async (param: string | google.maps.LatLng | null): Promise<iRespGeocoder | undefined> => {

        try {
            let obj = typeof param === 'string' ? { address: param } : param instanceof google.maps.LatLng ? { location: param } : null
            if (!obj) {
                console.log("params mal formatado")
                return
            }
            console.log("Search by ", obj)
            const geocoder = new google.maps.Geocoder();
            let { results } = await geocoder.geocode(obj)
            let address = results && results[0].formatted_address
            let location = results && results[0].geometry.location
            let resp: iRespGeocoder = {
                address,
                position: { lat: location.lat(), lng: location.lng() }
            }
            return resp
        } catch (error) {
            console.log(error)
            return
        }
    }

    const coordinateToAddress = async (location: GoogleLatLng) => {
        console.log("coordinateToAddress", location)
        let respGeocoder = await GeocoderService(location)
        if (respGeocoder) {
            setMsgBotton(respGeocoder.address)
            addOneMarkerinMap({
                position: location,
                isDragable: true
            })
        }
    };


    const AddressToCoordinate = async (params: string) => {

        let respGeocoder = await GeocoderService(params)
        if (respGeocoder) {
            setMsgBotton(respGeocoder.address)
            addOneMarkerinMap({
                position: respGeocoder.position,
                isDragable: true
            })
        }
    };




    const moveInMap = (mkr: google.maps.Marker) => {
        let location = mkr.getPosition()
        let center = new google.maps.LatLng(location?.lat() || 0, location?.lng() || 0)
        map?.panTo(center)
        if (map?.getZoom() !== zoomChanged) map?.setZoom(zoomChanged)

    }
    /*------------------------------------------------------
    addMarkerInMap
    --------------------------------------------------------*/
    const addOneMarkerinMap = (mkr: iMarker): void => {

        if (vMarker) {
            vMarker.setMap(null);
            google.maps.event.clearInstanceListeners(vMarker);
        }

        let Marker: GoogleMarker = new google.maps.Marker({
            position: mkr.position,
            map: map,
            icon: getIconAttributes('#000000'),
            draggable: mkr.isDragable
        });

        moveInMap(Marker)
        vMarker = Marker
        setMarker(Marker)

        google.maps.event.addListener(vMarker, 'dragend', (e: any) => {
            if (vMarker) moveInMap(vMarker)
            if (e.latLng) { coordinateToAddress(e.latLng) }
        })
    };

    const addListMarker = (mkrs: iMarker[]): void => {
        if (mkrs.length > 0) {
            if (markerCluster) markerCluster.clearMarkers()
            vListMarker && vListMarker.forEach(mkr => {
                mkr.setMap(null)
            })
            vListMarker = []
            let bounds = new google.maps.LatLngBounds();
            mkrs.forEach(mkr => {
                const icon = {
                    url: mkr.icon ?? '', // url
                    scaledSize: new google.maps.Size(40, 40), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(20, 20) // anchor
                };
                let new_marker: GoogleMarker = new google.maps.Marker({
                    position: new google.maps.LatLng(mkr.position),
                    map: map,
                    label: {

                        text: mkr.label ?? '',
                        className: 'marker-label',

                    },
                    icon: icon,
                    draggable: mkr.isDragable
                });
                bounds.extend(mkr.position)
                vListMarker.push(new_marker)
            });
            markerCluster = new MarkerClusterer({ map, markers: vListMarker });
            if (!mapOptions.noFitBounds) {
                console.log("fitBoounds")
                map?.fitBounds(bounds);
            }
            if (vListMarker) setListMarkers(vListMarker)
        }
    }


    const addPolyline = (polyline: string | undefined): void => {
        if (polyline && polyline.length > 0) {
            let LatLngArray: GoogleLatLng[] = google.maps.geometry.encoding.decodePath(polyline)
            //console.log(LatLngArray)
            vPolyLine = new google.maps.Polyline({
                path: LatLngArray,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map
            });
        }
    }


    // let points = window.google.maps.geometry.encoding.decodePath(data.geometry)
    //console.log(points)
    //setPolyline([])
    // let poly: iPolyline[] = []
    // let py: iPolyline = {
    //     paths: points
    // }
    // poly.push(py)
    // setPolyline(poly)



    useEffect(() => {
        if (map) {
            console.log("Google Maps Started")
            google.maps.event.addListener(map, "zoom_changed", () => {
                zoomChanged = map.getZoom() ?? zoomClickMap
                //console.log("New Zoom", zoomChanged)
            })
        }

    }, [map]);

    useLayoutEffect(() => {
        console.log("useLayoutEffect googlemaps")
        if (ref.current) {
            setStyleMaps({
                height: ref.current.offsetHeight + "px"
            })
        }
    }, [])




    const getIconAttributes = (iconColor: string) => {
        return {
            path: 'M11.0639 15.3003L26.3642 2.47559e-05L41.6646 15.3003L26.3638 51.3639L11.0639 15.3003 M22,17.5a4.5,4.5 0 1,0 9,0a4.5,4.5 0 1,0 -9,0Z',
            fillColor: iconColor,
            fillOpacity: 0.8,
            strokeColor: 'pink',
            strokeWeight: 2,
            anchor: new google.maps.Point(30, 50)
        };
    };

    const initMap = (): void => {
        if (!WaitLoaderMap) {
            console.log("Start GoogleMaps")
            WaitLoaderMap = true

            if (ref.current) {
                let _map = new google.maps.Map(ref.current, {
                    zoom: zoomInit,
                    center: centerBrazil,
                    mapTypeControl: true,
                    streetViewControl: false,
                    rotateControl: true,
                    scaleControl: true,
                    fullscreenControl: true,
                    panControl: true,
                    zoomControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.BOTTOM_LEFT,
                    },
                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                    gestureHandling: 'greedy',// 'cooperative',//controla o zoom do mapa sem o CTRl
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    draggableCursor: 'pointer',
                })
                setMap(_map)
                google.maps.event.addListenerOnce(_map, "idle", () => {
                    console.log("Google Maps Load")
                    WaitLoaderMap = false
                })

                zoomChanged = zoomClickMap
            }
        } else {
            console.log("Google Maps Loading...")
        }
    };


    const handleCloseMap = () => {
        dispatch(changeMenu({ openMap: 0 }))
        //limpar o mapa
    }

    const handleSaveClick = () => {
        dispatch(changeMenu({ openMap: 0 }))
        let mapConfig: iReduxMap = {
            response: {
                ender: msgBottom ?? '',
                position: { lat: marker?.getPosition()?.lat() ?? 0, lng: marker?.getPosition()?.lng() ?? 0 }

            }
        }
        dispatch(changeMap(mapConfig))
    }

    const handleSearchMap = (val: string) => {
        if (val.length > 0) {
            AddressToCoordinate(val)
        }
    }

    return (
        <div style={StylePanel}>
            <ToolbarContainer>
                <BarTable CtrlButtons={CtrlBtns} onCancelClick={handleCloseMap} onSearchValue={handleSearchMap} onSaveClick={handleSaveClick} />
            </ToolbarContainer>
            <MapObject ref={ref} style={StyleMaps}></MapObject>
            <div className='txtStatus'>{msgBottom}</div>
        </div>
    );
};

export default GoogleMapsV2;
