import React from 'react';
import { itemSettingsAlerts } from '../../interfaces/types';
import SelectComp from './components/SelectFieldComp';
import styled from 'styled-components';

//form
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { ContainerBlock, ContainerFlexInline } from '../../styles/Styles';
import Input from './components/InputComp';
import { Save } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import httpCommon from '../../services/http-common';
import { changeAlert } from '../../redux/alertServiceSlice';
import { iMsgAlert } from '../../interfaces/interfaces';

const ContainerSelect = styled.div`
display: block;
& > select {
    text-align: left;
    width: 220px;
    border: 1px solid #b1b1b1;
}`

const ContainerForm = styled('div')({
    display: 'block',
    padding: 8
});

const ContainerInput80px = styled.div`
display: block;
margin-right: 10px;
& > Input {
    text-align: left;
    width: 80px;
    border: 1px solid #b1b1b1;
}`
const ContainerInput = styled.div`
display: block;
margin-right: 10px;
& > Input {
    text-align: left; 
    width: 220px;
    border: 1px solid #b1b1b1;
}`


const ButtonDiv = styled('button')({
    display: 'block',
    padding: 8
});


interface iOptions {
    label: string
    value: string
}

const listAlerts: iOptions[] = [
    { label: "Ignição", value: "IGN" },
    { label: "Corte de Bateria", value: "BATCUT" },
    { label: "Cerca", value: "CERCA" },
    { label: "Movimento", value: "MOVE" },
    { label: "Pânico", value: "PANICO" },
    { label: "Bloqueio", value: "BLK" },
    { label: "Velocidade", value: "VEL" },
    { label: "Manutenção", value: "MANUT" },
    { label: "Veículo Parado", value: "STOPMOVE" },
    { label: "Plataforma Guincho", value: "GUINCHO" },
    { label: "Sensor de Chuva", value: "RAIN" }
]

const listPrior: iOptions[] = [
    { label: "Baixa", value: "1" },
    { label: "Média", value: "2" },
    { label: "Alta", value: "3" }
]
const listSons: iOptions[] = [
    { label: "Toque Padrão", value: "0" },
    { label: "Alerta por Voz", value: "1" },
    { label: "Sirene", value: "2" },
    { label: "Alarme", value: "3" },
]
const listNAlertas: iOptions[] = [
    { label: "1 Alerta", value: "1" },
    { label: "2 Alertas", value: "2" },
    { label: "3 Alertas", value: "3" },
    { label: "5 Alertas", value: "5" },
    { label: "10 Alertas", value: "10" },
    { label: "15 Alertas", value: "15" },
    { label: "20 Alertas", value: "20" },
]
const listInterval: iOptions[] = [
    { label: "1 Minuto", value: "1" },
    { label: "3 Minutos", value: "3" },
    { label: "5 Minutos", value: "5" },
    { label: "10 Minutos", value: "10" },
    { label: "15 Minutos", value: "15" },
    { label: "20 Minutos", value: "20" },
    { label: "30 Minutos", value: "30" },
    { label: "45 Minutos", value: "45" },
    { label: "50 Minutos", value: "50" },
    { label: "1 Hora", value: "60" },
]

const opcsOnOff: iOptions[] = [
    { label: "Ligada e Desligada", value: "0" },
    { label: "Ligada", value: "1" },
    { label: "Desligada", value: "2" }
]
const opcsCerca: iOptions[] = [
    { label: "Entrada e Saída", value: "0" },
    { label: "Entrada", value: "1" },
    { label: "Saída", value: "2" }
]
const opcsBat: iOptions[] = [
    { label: "Conectada e Desconectada", value: "0" },
    { label: "Conectada", value: "1" },
    { label: "Desconectada", value: "2" }
]
const opcsBlk: iOptions[] = [
    { label: "Bloqueado e Desbloqueado", value: "0" },
    { label: "Bloqueado", value: "1" },
    { label: "Desbloqueado", value: "2" }
]
const opcsPlat: iOptions[] = [
    { label: "Acionada e Recolhida", value: "0" },
    { label: "Acionada", value: "1" },
    { label: "Recolhida", value: "2" }
]
const opcsRain: iOptions[] = [
    { label: "Chovendo e Sem Chuva", value: "0" },
    { label: "Chovendo", value: "1" },
    { label: "Sem Chuva", value: "2" }
]
const opcsDoor: iOptions[] = [
    { label: "Aberta e Fechada", value: "0" },
    { label: "Aberta", value: "1" },
    { label: "Fechada", value: "2" }
]

interface FormDataSave {
    alerta_id: number
    iduser: number
    idempresa: number
    descr: string,
    tipo: string,
    param1: string,
    priority: number
    points: number
    nalertas: number
    intervalo: number
    sons: number
    evento: number
}


interface ChildProps {
    closeForm: () => void
    initialData?: itemSettingsAlerts
}

export interface ChildRef {
    callSubmit: () => void;
}

const AlertForms = React.forwardRef<ChildRef, ChildProps>((props, ref) => {

    const dispatch = useDispatch()
    const formRef = React.useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const [listEvents, setListEvents] = React.useState<iOptions[]>([]);
    const [alertType, setTypeAlert] = React.useState<string>();


    React.useImperativeHandle(ref, () => ({
        callSubmit
    }));

    const callSubmit = () => {
        formRef.current?.submitForm()
    }

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }


    const handleSaveInServer = async (data: FormDataSave) => {
        const result = data.alerta_id ? await httpCommon.post("EditRegra", data) : await httpCommon.post("CriarRegra", data)
        let resp = result.data;
        if (resp.IsSuccess) {
            handleSetMsgInfo({ msg: `Registro ${data.alerta_id ? 'Alterado' : 'Criado'} com sucesso!`, type: 'success' })
            props.closeForm()
        } else {
            handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            formRef.current?.setFieldError(resp.field, resp.msg)
        }
        if (resp.IsSuccess) {
            formRef.current?.reset()
        }

    }

    const handleSubmitSaveCad: SubmitHandler<FormDataSave> = async data => {
        try {
            formRef.current?.setErrors({})
            const schema = yup.object().shape({
                descr: yup.string().required("Esse campo é Obrigatório")
            });
            await schema.validate(data, {
                abortEarly: false,
            });
            data.idempresa = user.idempresa
            data.iduser = user.idcliente
            data.alerta_id = props.initialData?.alerta_id ?? 0
            handleSaveInServer(data)
        } catch (err) {
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }
    const handleChangeEvts = () => {
        let value = formRef.current?.getFieldValue('tipo')
        setTypeAlert(value)
        switch (value) {
            case "IGN":
                setListEvents(opcsOnOff)
                return;
            case "CERCA":
                setListEvents(opcsCerca)
                return;
            case "BATCUT":
                setListEvents(opcsBat)
                return;
            case "BLK":
                setListEvents(opcsBlk)
                return;
            case "GUINCHO":
                setListEvents(opcsPlat)
                return;
            case "RAIN":
                setListEvents(opcsRain)
                return;
            case "DOOR_RIDE":
                setListEvents(opcsDoor)
                return;

            default:
                setListEvents([])
                break;
        }

    }

    React.useEffect(() => {
        console.log("UseEffect FormAlerts", props.initialData)
        if (!props.initialData) {
            formRef.current?.setFieldValue('tipo', 'IGN')
            formRef.current?.setFieldValue('points', '0')
        }
        handleChangeEvts()
    }, [])

    return (
        <>
            <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveCad} className='Input-Style' noValidate={true} initialData={props.initialData}>
                <ContainerFlexInline>
                    <ContainerForm>
                        <ContainerInput> <Input className='theme-light' name="descr" label='Descrição' placeholder='Descrição' role="presentation" type='text' /></ContainerInput>
                        <ContainerSelect><SelectComp name="sons" options={listSons} label="Sons" /></ContainerSelect>
                        <ContainerSelect><SelectComp name="nalertas" options={listNAlertas} label="Num. Alertas" /></ContainerSelect>
                        <ContainerSelect><SelectComp name="intervalo" options={listInterval} label="Intervalo" /></ContainerSelect>
                        <ContainerSelect><SelectComp name="priority" options={listPrior} label="Prioridade" /></ContainerSelect>
                        <ContainerInput> <Input className='theme-light' name="points" label='Pontos' placeholder='Pontos' role="presentation" type='number' /></ContainerInput>
                    </ContainerForm>
                    <ContainerForm>
                        <ContainerSelect><SelectComp onChange={handleChangeEvts} name="tipo" options={listAlerts} label="Tipo de Alerta" /></ContainerSelect>
                        {listEvents.length > 0 && <ContainerSelect><SelectComp name="evento" options={listEvents} label="Evento" /></ContainerSelect>}
                        {alertType === 'VEL' && <ContainerInput80px> <Input className='theme-light' name="valor" label='Velocidade' placeholder='Velocidade' role="presentation" type='number' /></ContainerInput80px>}
                        {alertType === 'STOPMOVE' && <ContainerInput80px> <Input className='theme-light' name="tempo" label='Tempo' placeholder='Tempo' role="presentation" type='number' /></ContainerInput80px>}
                    </ContainerForm>
                </ContainerFlexInline>
            </Form>
        </>
    )

})
export default AlertForms