import React from 'react';
import { itemSettingsAlerts, itemVehicle } from '../../interfaces/types';
import SelectComp from '../forms/components/SelectFieldComp';
import styled from 'styled-components';

//form
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { ContainerBlock, ContainerFlexInline } from '../../styles/Styles';
import Input from '../forms/components/InputComp';
import { Save } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import httpCommon from '../../services/http-common';
import WidgetDataGridComp from '../../widgets/WidgetDataGrid';
import { ctrlButtons } from '../../interfaces/interfaces';
import { GridColDef } from '@mui/x-data-grid';
import ShowVehicleNoAlertModal from '../Modal/ShowVehicleNoAlertModal';


interface CompProps {
    alert: itemSettingsAlerts
}

const LabelItem = styled.div`
    display: block;
    font-size: medium;
    color: #7e7e7e;
    width: 150px;
`
const InfoItem = styled.div`
    display: flex;
    padding-top: 8px;
`
const ValueItem = styled.div`
    display: block;
    margin-left: 10px;
    color: black;
`
const Container = styled('div')({
    display: 'block',
    padding: 8
});


const ImgBox = styled.img`
    margin: 0 auto;
    height: 26px;
`



const initialBtns: ctrlButtons = {
    RefreshShow: true,
    DeleteShow: true,
    addShow: true

}





const CardVehiclesAlert: React.FC<CompProps> = ({ alert }) => {

    const { user } = useSelector(selectUser);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isOpenModalVehicleSel, setOpenModalVehicleSel] = React.useState<boolean>(false);
    const [listDatagrid, setListDatagrid] = React.useState<itemVehicle[]>([]);
    const [selectdRows, setSelectdRows] = React.useState<number[]>([]);


    const GetDados = async () => {
        try {
            setIsLoading(true)
            let payload = {
                alerta_id: alert.alerta_id,
                iduser: user.iduser,
                nivel: user.nivel
            }
            const result = await httpCommon.post("ListarCarInRegra", payload)
            let resp: itemVehicle[] = result.data.dados;
            resp = resp.filter(i => {
                if (i.idveiculo > 0) {
                    i.id = i.idveiculo
                    return i
                }
            })
            setListDatagrid(resp)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const RemoveVehicles = async () => {
        try {
            if (!selectdRows.length) {
                return
            }
            setIsLoading(true)
            let payload = {
                alerta_id: alert.alerta_id,
                itens: selectdRows
            }
            console.log(payload)
            const result = await httpCommon.post("RmVehilesInRule", payload)
            GetDados()
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }



    React.useEffect(() => {
        GetDados()
    }, [])

    const columns: GridColDef<itemVehicle>[] = [
        {
            field: 'idveiculo',
            headerName: 'Cód',
            width: 80
        },
        {
            field: 'icon', headerName: '', width: 80,
            align: 'center',
            renderCell: (params) => {
                let img: string = ''
                try {
                    img = require(`../../assets/icons_maps/${params.row.avatar}.png`)
                } catch (error) {
                    console.log(params.formattedValue, params.row.avatar)
                }
                return (
                    <>
                        {<ImgBox src={img} alt={params.row.placa} />}
                    </>
                );
            }


        },
        {
            field: 'placa',
            headerName: 'Placa',
            width: 120
        },
        {
            field: 'marca',
            headerName: 'Marca',
            width: 200
        },
        {
            field: 'modelo',
            headerName: 'Modelo',
            width: 300
        },
    ];


    const handleRowClick = (item: itemSettingsAlerts) => {
        console.log(item)
    };
    const handleClose = () => {
        setOpenModalVehicleSel(false)
    };
    const CloseToUpdate = () => {
        console.log("CloseToUpdate")
        setOpenModalVehicleSel(false)
        GetDados()
    };

    const setSel = (itens: any) => {
        let i: number[] = []
        for (let x = 0; x < itens.length; x++) {
            i.push(Number(itens[x]));
        }
        setSelectdRows(i)
    }

    return (
        <>
            {<ShowVehicleNoAlertModal onClose={handleClose} open={isOpenModalVehicleSel} alert={alert} onCloseToUpdate={CloseToUpdate} />}
            <WidgetDataGridComp
                isLoading={isLoading}
                rows={listDatagrid}
                columns={columns}
                CheckBoxSelect={true}
                initialBtns={initialBtns}
                onRowClick={handleRowClick}
                onRefresClick={() => GetDados()}
                onAddClick={() => setOpenModalVehicleSel(true)}
                handleSelRow={(newSelectionModel) => {
                    setSel(newSelectionModel)
                }}
                onDeleteClick={() => RemoveVehicles()}
            />
        </>
    )

}
export default CardVehiclesAlert