import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { iCateg, iDrivers, iItemDespesa, iItemTravel, iMsgAlert, iSelectDespesa, iVehicle } from '../../interfaces/interfaces';
import { ContainerBlock, ContainerFlexInline } from '../../styles/Styles';
import CardCategWidget from '../Widgets/CardCategWidget';
import CardDriverWidget from '../Widgets/CardDriverWidget';
import CardTravelWidget from '../Widgets/CardTravelWidget';
import CardVehicleWidget from '../Widgets/CardVehicleWidget';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import httpCommon from '../../services/http-common';
import { ConverterNumber } from '../../utils/Converter';



import DatePicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import ptBr from 'date-fns/locale/pt-BR';
import SelectComp from '../forms/components/SelectFieldComp';
import Input from '../forms/components/InputComp';
import { changeAlert } from '../../redux/alertServiceSlice';
import { DateFormatter, DateFormatterBd, DateTimeFormatter, DateTimeFormatterBd, FormatterDecimal, TimeFormatter } from '../../utils/format';
registerLocale('ptBr', ptBr)

const Container = styled('div')({
    background: '#f6f9ff',
    width: '100%',
    height: 500,
    display: 'flex',
});

interface DialogProps {
    open: boolean
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
    ParamsData?: iItemDespesa
}

const ContainerForm = styled('div')({
    display: 'block',
    padding: 8
});

const ContainerDate = styled.div`
    display: block;`

const ContainerHours = styled.div`
    display: block;
    margin-left: 10px;
    & > Input {
        text-align: center;
        width: 80px;
        border: 1px solid #b1b1b1;
    }`
const ContainerInput80px = styled.div`
display: block;
margin-right: 10px;
& > Input {
    text-align: left;
    width: 80px;
    border: 1px solid #b1b1b1;
}`

const ContainerSelect = styled.div`
display: block;
& > select {
    text-align: left;
    width: 220px;
    border: 1px solid #b1b1b1;
}`

const ContainerPicker = styled.div`
    display: flex;
`
const ContainerInline = styled.div`
    display: flex;
`

const ContainerCard = styled.div`
      display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px;
    background: #ffffff;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 15%);
    width: 300px;
`

interface iTypeFuel {
    label: string
    value: string
}

interface FormDataSave {
    id: number
    IdClient: number
    IdMotorista: number
    IdViagem: number
    idveiculo: number
    IdCateg: number
    descr: string
    amount: number
    evtType: string
    status: string
    lat?: string
    lng?: string
    photo1?: string
    photo2?: string
    photo3?: string
    dtevt: string
    km_start?: number
    km_end?: number
    km_vehicle?: number
    km_run: number
    avg_fuel: number
    type_unity?: string
    unity?: string
    qty?: number
    price_unity?: number
    obs?: string
    uniqueId: string
    param1: string //litros
    param2: string //valor litro
    param3: string // km_ant
    param4: string //km_atual
    param5: string //tipo comb
}



const DespesasModal: React.FC<DialogProps> = (props) => {

    const dispatch = useDispatch()
    const formRef = useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isOpenDataPicker, setOpenDataPicker] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [DateSelected, setDateSelected] = React.useState<string>();
    const [HoursSelected, setHoursSelected] = React.useState<string>();
    const [Avg, setAvg] = React.useState<number>();
    const [kmRun, setKmRun] = React.useState<number>();
    const [ListFuel, setListFuel] = React.useState<iTypeFuel[]>([]);

    const [CategSel, setCategSel] = React.useState<iCateg>();
    const [VehicleSel, setVehicleSel] = React.useState<iVehicle>();
    const [DriverSel, setDriverSel] = React.useState<iDrivers>();
    const [TravelSel, setTravelSel] = React.useState<iItemTravel>();




    const UpdateKm = (vehicle: iVehicle) => {
        if (vehicle) {
            formRef.current?.setFieldValue('km_start', vehicle.km_last_abast);
            formRef.current?.setFieldValue('km_end', vehicle.km_total);
            formRef.current?.setFieldValue('km_vehicle', vehicle.km_total);
            onChangeCalcKm()
        }
    }

    const selectCateg = (item: iCateg) => {
        setCategSel(item)
    }
    const selectVehicle = (item: iVehicle) => {
        setVehicleSel(item)
        if (CategSel?.id === 1) { UpdateKm(item) }


    }
    const selectDriver = (item: iDrivers) => {
        setDriverSel(item)
    }
    const selectTravel = (item: iItemTravel) => {
        setTravelSel(item)
    }

    const handleChangeDataPicker = (newValue: any) => {
        setOpenDataPicker(false)
        setStartDate(newValue)
        setDateSelected(DateFormatterBd(newValue))
    };

    const handleGetListFuel = async () => {
        const result = await httpCommon.post("getListFuel", { idclient: user.idcliente, price_fix: 0, dtupdate: 0 })
        let resp = result.data;
        console.log(resp)
        let list = resp.map((i: any) => {
            return {
                label: i.descr,
                value: i.descr
            }
        })
        setListFuel(list)
    }

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }

    const handleSaveInServer = async (data: FormDataSave) => {
        const result = data.id ? await httpCommon.post("upDespesas", data) : await httpCommon.post("saveDespesas", data)
        let resp = result.data;
        if (resp.IsSuccess) {
            handleSetMsgInfo({ msg: `Registro ${data.id ? 'Alterado' : 'Criado'} com sucesso!`, type: 'success' })
        } else {
            handleSetMsgInfo({ msg: resp.msg, type: 'error' })
            formRef.current?.setFieldError(resp.field, resp.msg)
        }
        if (resp.IsSuccess) {
            formRef.current?.reset()
        }

    }


    const handleSubmitSaveCad: SubmitHandler<FormDataSave> = async data => {
        try {
            formRef.current?.setErrors({})
            data.IdClient = user.idcliente
            data.dtevt = DateTimeFormatterBd(`${DateSelected} ${HoursSelected}`)
            data.IdMotorista = DriverSel?.id ?? 0
            data.idveiculo = VehicleSel?.id ?? 0
            data.IdCateg = CategSel?.id ?? 0
            data.IdViagem = TravelSel?.id ?? 0
            data.amount = Number(FormatterDecimal(data.amount))
            data.km_vehicle = Number(FormatterDecimal(VehicleSel?.km_total))
            data.evtType = CategSel?.catType ?? ''
            data.km_run = kmRun ?? 0
            data.avg_fuel = Avg ?? 0
            if (CategSel?.id === 1) {
                data.price_unity = Number(FormatterDecimal(data.price_unity))
                data.qty = Number(FormatterDecimal(data.qty))
                data.km_start = Number(FormatterDecimal(data.km_start))
                data.km_end = Number(FormatterDecimal(data.km_end))
                data.type_unity = data.type_unity
                data.param1 = data.qty.toString() //litros
                data.param2 = data.price_unity.toString() //valor litro
                data.param3 = data.km_start.toString() // km_ant
                data.param4 = data.km_end.toString() //km_atual
                data.param5 = data.type_unity ?? '' //tipo comb
            }
            if (props.ParamsData) {
                data.id = props.ParamsData.id
                data.uniqueId = props.ParamsData.uniqueId
            } else {
                data.uniqueId = uuidv4()
            }
            console.log(data)
            //shpe
            let itemShape: any = {
                descr: yup.string().required("Esse campo é Obrigatório"),
                amount: yup.number().typeError('Esse campo é Obrigatório').required('Esse campo é Obrigatório').moreThan(0, "Valor precisa ser maior que 0")
            }
            if (CategSel?.id === 1) {
                itemShape.km_start = yup.number().typeError('Esse campo é Obrigatório').required('Esse campo é Obrigatório').moreThan(0, "Valor precisa ser maior que 0")
                itemShape.km_end = yup.number().typeError('Esse campo é Obrigatório').required('Esse campo é Obrigatório').moreThan(yup.ref('km_start'), "Km Final precisa ser maior que o Inicial")
                itemShape.price_unity = yup.number().typeError('Esse campo é Obrigatório').required('Esse campo é Obrigatório').moreThan(0, "Valor precisa ser maior que 0")
                itemShape.qty = yup.number().typeError('Esse campo é Obrigatório').required('Esse campo é Obrigatório').moreThan(0, "Valor precisa ser maior que 0")
            }
            const schema = yup.object().shape(itemShape);
            await schema.validate(data, {
                abortEarly: false,
            });

            handleSaveInServer(data)
        } catch (err) {
            console.log(err)
            let validationErrors: Record<string, string> = {};
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    }

    function SubmitsForm() {
        formRef.current?.submitForm();
    }

    React.useEffect(() => {
        formRef.current?.setFieldValue('descr', CategSel?.descr);
        if (CategSel?.id === 1) {
            handleGetListFuel()
            if (VehicleSel) { UpdateKm(VehicleSel) }
        }
    }, [CategSel?.id])


    const PrepareData = () => {
        let hours = TimeFormatter(props.ParamsData?.dtevt)
        setHoursSelected(hours)
        setDateSelected(DateFormatterBd(props.ParamsData?.dtevt))
        setStartDate(new Date(DateTimeFormatterBd(props.ParamsData?.dtevt)))
    }

    React.useEffect(() => {
        PrepareData()
    }, [props.ParamsData])

    const formData = (
        <React.Fragment>
            <ContainerDate>
                <label >Data</label>
                <DatePicker
                    selected={startDate}
                    onChange={handleChangeDataPicker}
                    popperPlacement="top-end"
                    locale="ptBr"
                    dateFormat="dd-MM-yyyy"
                    className='InputDate'

                />
            </ContainerDate>
        </React.Fragment>
    );

    const onChangeHours = (event: any) => {
        const { value } = event.target;
        setHoursSelected(value);
    }


    const onChangeCalcFuel = (field: string) => {
        let qty = formRef.current?.getFieldValue('qty');
        let price_unity = formRef.current?.getFieldValue('price_unity');
        let amount = formRef.current?.getFieldValue('amount');
        let isFieldCalcAmount = field === 'qty' || field === 'price_unity'
        let isFieldCalcQty = field === 'amount' || field === 'price_unity'
        let isFieldCalcPrice = field === 'qty' || field === 'amount'
        let isCalAmount = qty > 0 && price_unity > 0 && isFieldCalcAmount
        let isCalQty = amount > 0 && price_unity > 0 && isFieldCalcQty
        let isCalPrice = amount > 0 && qty > 0 && isFieldCalcPrice

        if (isCalAmount) {
            let amountCal = price_unity * qty
            formRef.current?.setFieldValue('amount', FormatterDecimal(amountCal));
            return
        }

        if (isCalQty) {
            let qtyCal = amount / price_unity
            formRef.current?.setFieldValue('qty', FormatterDecimal(qtyCal));
            return
        }

        if (isCalPrice) {
            let priceCal = amount / qty
            formRef.current?.setFieldValue('price_unity', FormatterDecimal(priceCal));
            return
        }

    }


    const onChangeCalcKm = () => {
        let km_start = formRef.current?.getFieldValue('km_start');
        let km_end = formRef.current?.getFieldValue('km_end');
        let qty = formRef.current?.getFieldValue('qty');
        let isCalKm = km_start > 0 && km_end > 0
        let isCalAvg = isCalKm && qty > 0
        let kmRun = 0;
        let Avg = 0
        if (isCalKm) {
            kmRun = km_end - km_start
            setKmRun(Number(FormatterDecimal(kmRun)))
        }
        if (isCalAvg) {
            Avg = kmRun / qty
            setAvg(Number(FormatterDecimal(Avg)))
        }
    }


    const formHours = (
        <React.Fragment>
            <ContainerHours>
                <label >Hora</label>
                <InputMask {...props} mask="99:99:99" maskChar="_" onChange={onChangeHours} value={HoursSelected} />
            </ContainerHours>
        </React.Fragment>
    );
    const formKm = (
        <React.Fragment>
            <ContainerForm>
                <ContainerSelect><SelectComp name="type_unity" options={ListFuel} label="Tipo de Combustível" /></ContainerSelect>
                <ContainerInline>
                    <ContainerInput80px> <Input className='theme-light' onChange={onChangeCalcKm} name="km_start" label='Km Inícial' placeholder='Km Inícial' disabled={isLoading} role="presentation" type='text' /></ContainerInput80px>
                    <ContainerInput80px>  <Input className='theme-light' onChange={onChangeCalcKm} name="km_end" label='Km Atual' placeholder='Km Atual' disabled={isLoading} role="presentation" type='text' /></ContainerInput80px>
                </ContainerInline>
                <div>{kmRun} Km Percorrido</div>
                <div>Média: {Avg} Km/L</div>
            </ContainerForm>
        </React.Fragment>
    );

    const formOthers = (
        <React.Fragment>
            <ContainerForm>
                <Input className='theme-light' name="descr" label='Descrição' placeholder='Descrição' disabled={isLoading} role="presentation" type='text' />
                {CategSel?.id === 1 && <ContainerInline>
                    <ContainerInput80px> <Input className='theme-light' name="price_unity" onChange={(e) => onChangeCalcFuel('price_unity')} label='Preço/Litro' placeholder='Preço/Litro' disabled={isLoading} role="presentation" type='number' /></ContainerInput80px>
                    <ContainerInput80px> <Input className='theme-light' name="qty" onChange={(e) => onChangeCalcFuel('qty')} label='Litros' placeholder='Litros' disabled={isLoading} role="presentation" type='number' /></ContainerInput80px>
                </ContainerInline>}
                <Input className='theme-light' name="amount" label='Valor' placeholder='Valor' onChange={(e) => onChangeCalcFuel('amount')} disabled={isLoading} role="presentation" type='number' />
            </ContainerForm>
        </React.Fragment>
    );



    return <>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, minHeight: 500 } }}
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Cadastro de Despesas</DialogTitle>
            <DialogContent>
                <ContainerBlock>
                    <ContainerFlexInline>
                        {<CardCategWidget idCateg={props.ParamsData?.idcateg} selectCateg={selectCateg} />}
                        {<CardVehicleWidget idVehicle={props.ParamsData?.idvehicle} selectVehicle={selectVehicle} />}
                        {<CardDriverWidget idMotorista={props.ParamsData?.idmotorista} selectDriver={selectDriver} />}
                        {<CardTravelWidget idTravel={props.ParamsData?.idtravel} selectTravel={selectTravel} />}
                    </ContainerFlexInline>
                    <ContainerBlock>
                        <Form placeholder={""} ref={formRef} onSubmit={handleSubmitSaveCad} className='Input-Style' noValidate={true} initialData={props.ParamsData}>
                            <ContainerFlexInline>

                                <ContainerCard>
                                    <ContainerPicker>
                                        {formData}
                                        {formHours}
                                    </ContainerPicker>
                                    {formOthers}
                                </ContainerCard>

                                {CategSel?.id === 1 &&
                                    <Container>
                                        <ContainerCard>
                                            {formKm}
                                        </ContainerCard>
                                    </Container>
                                }
                            </ContainerFlexInline>
                        </Form>
                    </ContainerBlock>

                </ContainerBlock>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} >
                    Cancelar
                </Button>
                <Button onClick={SubmitsForm}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog></>

}

export default DespesasModal